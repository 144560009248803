import React, { useState } from 'react';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import './ImagesToPDF.css';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import '../pdf.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ImagesToPDF = () => {
  const [imageFiles, setImageFiles] = useState([]);
  const [convertedPdfBlob, setConvertedPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

  const handleFileChange = (e) => {
    setImageFiles([...e.target.files]);
  };

  const handleConvertToPdf = async () => {
    setIsLoading(true);
    const formData = new FormData();
    imageFiles.forEach((file) => {
      formData.append('imageFiles', file);
    });

    try {
      const response = await axios.post(
        `${baseUrl}/pdf/images-to-pdf`,
        formData,
        { responseType: 'blob' }
      );
      setConvertedPdfBlob(
        new Blob([response.data], { type: 'application/pdf' })
      );
    } catch (error) {
      console.error('Error converting images to PDF:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadConvertedPdf = () => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(convertedPdfBlob);
    link.setAttribute('download', 'converted_images_pdf.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="pdf-utility-container">
      <h2>Convert Images to PDF</h2>
      <Input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        multiple
      />
      <Button
        onClick={handleConvertToPdf}
        disabled={imageFiles.length === 0 || isLoading}
      >
        Convert to PDF
      </Button>
      {convertedPdfBlob && (
        <div>
          <Document file={convertedPdfBlob}>
            <Page pageNumber={1} width={600} />
          </Document>
          <Button onClick={downloadConvertedPdf}>Download PDF</Button>
        </div>
      )}
    </div>
  );
};

export default ImagesToPDF;
