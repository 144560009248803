import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './MarkdownPreview.css';

const MarkdownPreview = () => {
  const [markdown, setMarkdown] = useState('# Hello, World!\n\nThis is a markdown preview.');

  return (
    <div className="markdown-preview-container">
      <div className="markdown-input">
        <textarea
          value={markdown}
          onChange={(e) => setMarkdown(e.target.value)}
          placeholder="Enter your markdown here..."
        />
      </div>
      <div className="markdown-output">
        <ReactMarkdown>{markdown}</ReactMarkdown>
      </div>
    </div>
  );
};

export default MarkdownPreview;