import React, { useState } from 'react';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import './ExtractPDFPages.css';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import '../pdf.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ExtractPDFPages = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [extractedPdfBlob, setExtractedPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';
  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
    setSelectedPages([]);
  };

  const handleExtractPages = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('pdfFile', pdfFile);
    formData.append('pageNumbers', JSON.stringify(selectedPages));

    try {
      const response = await axios.post(
        `${baseUrl}/pdf/extract-pages`,
        formData,
        { responseType: 'blob' }
      );
      setExtractedPdfBlob(
        new Blob([response.data], { type: 'application/pdf' })
      );
    } catch (error) {
      console.error('Error extracting PDF pages:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pdf-utility-container">
      <h2>Extract PDF Pages</h2>
      <Input type="file" accept="application/pdf" onChange={handleFileChange} />
      {pdfFile && (
        <div>
          <Button onClick={handleExtractPages} disabled={!pdfFile || isLoading}>
            Extract Pages
          </Button>
          <div className="pdf-container">
            <Document file={pdfFile}>
              {selectedPages.map((pageNumber) => (
                <Page key={pageNumber} pageNumber={pageNumber} width={100} />
              ))}
            </Document>
          </div>
        </div>
      )}
      {extractedPdfBlob && (
        <div>
          <Document file={extractedPdfBlob}>
            <Page pageNumber={1} width={600} />
          </Document>
        </div>
      )}
    </div>
  );
};

export default ExtractPDFPages;
