import React, { useState } from 'react';
import axios from 'axios';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import '../image.css';
import './BulkImageResize.css';
const BulkImageResize = () => {
  const [imageFiles, setImageFiles] = useState([]);
  const [targetWidth, setTargetWidth] = useState('');
  const [targetHeight, setTargetHeight] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resizedImages, setResizedImages] = useState([]);

  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

  const handleImageChange = (e) => {
    setImageFiles([...e.target.files]);
  };

  const handleWidthChange = (e) => {
    setTargetWidth(e.target.value);
  };

  const handleHeightChange = (e) => {
    setTargetHeight(e.target.value);
  };

  const handleResizeImages = async () => {
    setIsLoading(true);
    const formData = new FormData();
    imageFiles.forEach((file) => {
      formData.append('imageFiles', file);
    });
    formData.append('targetWidth', targetWidth);
    formData.append('targetHeight', targetHeight);

    try {
      const response = await axios.post(
        `${baseUrl}/image/bulk-resize`,
        formData,
        { responseType: 'blob' }
      );
      setResizedImages([...resizedImages, URL.createObjectURL(response.data)]);
    } catch (error) {
      console.error('Error resizing images:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="image-utility-container">
      <h2>Bulk Image Resize</h2>
      <Input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageChange}
      />
      <Input
        type="number"
        value={targetWidth}
        onChange={handleWidthChange}
        placeholder="Target Width"
      />
      <Input
        type="number"
        value={targetHeight}
        onChange={handleHeightChange}
        placeholder="Target Height"
      />
      <Button
        onClick={handleResizeImages}
        disabled={imageFiles.length === 0 || isLoading}
      >
        Resize Images
      </Button>
      {resizedImages.length > 0 && (
        <div>
          <h3>Resized Images:</h3>
          {resizedImages.map((image, index) => (
            <img key={index} src={image} alt={`Resized ${index}`} />
          ))}
        </div>
      )}
    </div>
  );
};

export default BulkImageResize;
