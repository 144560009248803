import React, { useState } from 'react';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import './AddPageNumbersToPDF.css';
import '../pdf.css';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AddPageNumbersToPDF = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [modifiedPdfBlob, setModifiedPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const pageNumberOptions = {
    fontSize: 12,
    xOffset: 50,
    yOffset: 30,
    color: 'rgb(0, 0, 0)',
  };
  //   const [pageNumberOptions, setPageNumberOptions] = useState({
  //     fontSize: 12,
  //     xOffset: 50,
  //     yOffset: 30,
  //     color: 'rgb(0, 0, 0)',
  //   });

  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handleAddPageNumbers = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('pdfFile', pdfFile);
    formData.append('options', JSON.stringify(pageNumberOptions));

    try {
      const response = await axios.post(
        `${baseUrl}/pdf/add-page-numbers`,
        formData,
        { responseType: 'blob' }
      );
      setModifiedPdfBlob(
        new Blob([response.data], { type: 'application/pdf' })
      );
    } catch (error) {
      console.error('Error adding page numbers:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadModifiedPdf = () => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(modifiedPdfBlob);
    link.setAttribute('download', 'page_numbered_pdf.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="pdf-utility-container">
      <h2>Add Page Numbers to PDF</h2>
      <Input type="file" accept="application/pdf" onChange={handleFileChange} />
      <Button onClick={handleAddPageNumbers} disabled={!pdfFile || isLoading}>
        Add Page Numbers
      </Button>
      {modifiedPdfBlob && (
        <div>
          <Document file={modifiedPdfBlob}>
            <Page pageNumber={1} width={600} />
          </Document>
          <Button onClick={downloadModifiedPdf}>Download Modified PDF</Button>
        </div>
      )}
    </div>
  );
};

export default AddPageNumbersToPDF;
