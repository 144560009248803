import React, { useState } from 'react';
import { makeApiCall } from '../../utils/api';
import PropTypes from 'prop-types';
const InstagramBio = ({ apiEndpoint, title, description, howToUse }) => {
  const [input, setInput] = useState('');
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await makeApiCall(apiEndpoint, { input });
      setResult(data);
    } catch (error) {
      setResult({ error: 'Failed to generate Instagram bio. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="instagram-bio-container">
      <h1>{title}</h1>
      <p>{description}</p>
  
      <form onSubmit={handleSubmit}>
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter keywords or a brief description of yourself or your brand"
          rows="4"
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Generating...' : 'Generate Bio'}
        </button>
      </form>
      {result && (
        <div className="result">
          <h2>Generated Bio:</h2>
          <p>{result.bio}</p>
          <h3>Suggested Hashtags:</h3>
          <ul>
            {result.hashtags.map((hashtag, index) => (
              <li key={index}>#{hashtag}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

InstagramBio.propTypes = {
    apiEndpoint: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    howToUse: PropTypes.string.isRequired,
  };
  
  export default InstagramBio;