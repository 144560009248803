import React, { useState } from 'react';
import './NumberBaseConverter.css';

const NumberBaseConverter = () => {
  const [inputNumber, setInputNumber] = useState('');
  const [inputBase, setInputBase] = useState(10);
  const [outputBase, setOutputBase] = useState(2);
  const [outputNumber, setOutputNumber] = useState('');

  const convertBase = () => {
    try {
      const decimalNumber = parseInt(inputNumber, inputBase);
      const result = decimalNumber.toString(outputBase);
      setOutputNumber(result);
    } catch (error) {
      setOutputNumber('Invalid input');
    }
  };

  return (
    <div className="number-base-converter">
      <input
        type="text"
        value={inputNumber}
        onChange={(e) => setInputNumber(e.target.value)}
        placeholder="Enter number"
      />
      <select value={inputBase} onChange={(e) => setInputBase(parseInt(e.target.value))}>
        <option value={2}>Binary</option>
        <option value={8}>Octal</option>
        <option value={10}>Decimal</option>
        <option value={16}>Hexadecimal</option>
      </select>
      <select value={outputBase} onChange={(e) => setOutputBase(parseInt(e.target.value))}>
        <option value={2}>Binary</option>
        <option value={8}>Octal</option>
        <option value={10}>Decimal</option>
        <option value={16}>Hexadecimal</option>
      </select>
      <button onClick={convertBase}>Convert</button>
      <input type="text" value={outputNumber} readOnly placeholder="Result" />
    </div>
  );
};

export default NumberBaseConverter;