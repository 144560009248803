import React, { useState } from 'react';
import './JsonStringifier.css';
import Button from '../../../components/Button/Button';
import Textarea from '../../../components/Textarea/Textarea';
import '../json-utility.css'
const JsonStringifier = () => {
  const [inputJson, setInputJson] = useState('');
  const [stringifiedJson, setStringifiedJson] = useState('');

  const handleInputChange = (e) => {
    setInputJson(e.target.value);
  };

  const stringifyJson = () => {
    try {
      const json = JSON.parse(inputJson);
      // setStringifiedJson(JSON.stringify(json, null, 2));
      console.log(JSON.stringify(json));
      setStringifiedJson(JSON.stringify(json));
    } catch (error) {
      alert('Invalid JSON input');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(stringifiedJson);
    alert('Copied to clipboard!');
  };

  return (
    <div className="json-utility-container">
      <h2>JSON Stringifier</h2>
      <Textarea
        value={inputJson}
        onChange={handleInputChange}
        rows="4"
        cols="50"
        placeholder="Enter JSON here"
      />
      <Button onClick={stringifyJson}>Stringify JSON</Button>
      <Button onClick={copyToClipboard} disabled={!stringifiedJson}>
        Copy to Clipboard
      </Button>
      <div>
        <h3>Stringified JSON:</h3>
        <pre>{stringifiedJson}</pre>
      </div>
    </div>
  );
};

export default JsonStringifier;
