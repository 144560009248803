import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../routes/routes';
import './home.css';
/**
 * Description
 * @author Raghav Aadithya
 * @date 2024-01-14
 * @return {any}
 */
function getRandomGradient() {
  const gradients = [
    'linear-gradient(135deg, #e0eafc, #cfdef3)',
    'linear-gradient(135deg, #ffdde1, #ee9ca7)',
    'linear-gradient(135deg, #d3cce3, #e9e4f0)',
    'linear-gradient(135deg, #89f7fe, #66a6ff)',
    'linear-gradient(135deg, #ffecd2, #fcb69f)',
    'linear-gradient(135deg, #a1c4fd, #c2e9fb)',
    'linear-gradient(135deg, #fbc2eb, #a6c1ee)',
    'linear-gradient(135deg, #fddb92, #d1fdff)',
    'linear-gradient(135deg, #cfd9df, #e2ebf0)',
  ];
  return gradients[Math.floor(Math.random() * gradients.length)];
}

const Home = () => {
  const groupedRoutes = routes.reduce((acc, route) => {
    acc[route.type] = acc[route.type] || [];
    acc[route.type].push(route);
    return acc;
  }, {});

  return (
    <div className="home-container">
      {Object.entries(groupedRoutes).map(([type, typeRoutes]) => (
        <div key={type} className="card-group">
          <h2>{type}</h2>
          <div className="card-container">
            {typeRoutes.map((route) => (
              <div key={route.path} className="card">
                <Link
                  to={route.path}
                  style={{ background: getRandomGradient() }}
                >
                  <div className="card-content">
                    <h3>{route.menuTitle}</h3>
                    <p>
                      {route.description.length > 30
                        ? route.description.substring(0, 30) + '...'
                        : route.description}
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Home;