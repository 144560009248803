import React, { useState, useEffect } from 'react';
import { database } from '../../firebase';
import { ref, onValue } from 'firebase/database';
import { motion } from 'framer-motion';
import './footer.css'
const Footer = () => {
  const [viewCount, setViewCount] = useState(0);

  useEffect(() => {
    const dbPath =
      window.location.hostname === 'localhost'
        ? 'views/localhost'
        : 'views/counter';

    const countRef = ref(database, dbPath);
    const unsubscribe = onValue(countRef, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        setViewCount(data);
      }
    });

    return () => unsubscribe();
  }, []);

  const footerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <motion.footer
      variants={footerVariants}
      initial="hidden"
      animate="visible"className='foot'
    >
      <p>Site Views: {viewCount}</p>
    </motion.footer>
  );
};

export default Footer;