import axios from 'axios';

export const makeApiCall = async (endpoint, data) => {
  try {
    const response = await axios.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('API call failed:', error);
    throw error;
  }
};