import React, { useState } from 'react';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import './RemovePDFPages.css';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import '../pdf.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const RemovePDFPages = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [removedPdfBlob, setRemovedPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [numPages, setNumPages] = useState(0);

  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
    setSelectedPages([]);
  };

  const handleRemovePages = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('pdfFile', pdfFile);
    formData.append('pagesToRemove', JSON.stringify(selectedPages));

    try {
      const response = await axios.post(
        `${baseUrl}/pdf/remove-pages`,
        formData,
        { responseType: 'blob' }
      );
      setRemovedPdfBlob(new Blob([response.data], { type: 'application/pdf' }));
    } catch (error) {
      console.error('Error removing PDF pages:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadRemovedPdf = () => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(removedPdfBlob);
    link.setAttribute('download', 'removed_pages.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="pdf-utility-container">
      <h2>Remove PDF Pages</h2>
      <Input type="file" accept="application/pdf" onChange={handleFileChange} />
      <Input
        type="text"
        value={selectedPages.join(', ')}
        readOnly
        placeholder="Selected pages"
      />
      {pdfFile && (
        <Document
          file={pdfFile}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          className="pdf-document"
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={index} pageNumber={index + 1} width={100} />
          ))}
        </Document>
      )}
      <Button onClick={handleRemovePages} disabled={!pdfFile || isLoading}>
        Remove Pages
      </Button>
      {removedPdfBlob && (
        <div>
          <Document file={removedPdfBlob} className="pdf-document">
            <Page pageNumber={1} width={600} />
          </Document>
          <Button onClick={downloadRemovedPdf}>Download PDF</Button>
        </div>
      )}
    </div>
  );
};

export default RemovePDFPages;
