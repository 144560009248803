import React, { useState } from 'react';
import Textarea from '../../../components/Textarea/Textarea';
import Button from '../../../components/Button/Button';
import '../utility.css'

const CSSMinifier = () => {
  const [inputCSS, setInputCSS] = useState('');
  const [minifiedCSS, setMinifiedCSS] = useState('');

  const minifyCSS = (css) => {
    return css
      .replace(/\s+/g, ' ')
      .replace(/\/\*.*?\*\//g, '')
      .replace(/\s?\{\s?/g, '{')
      .replace(/\s?\}\s?/g, '}')
      .replace(/\s?\:\s?/g, ':')
      .replace(/\s?\;\s?/g, ';')
      .replace(/\s?\,\s?/g, ',');
  };

  const handleMinify = () => {
    const minified = minifyCSS(inputCSS);
    setMinifiedCSS(minified);
  };

  return (
    <div className="utility-container">
      <Textarea
        value={inputCSS}
        onChange={(e) => setInputCSS(e.target.value)}
        placeholder="Enter CSS here"
      />
      <Button onClick={handleMinify}>Minify CSS</Button>
      <Textarea
        value={minifiedCSS}
        readOnly
        placeholder="Minified CSS will appear here"
      />
    </div>
  );
};

export default CSSMinifier;
