import React from 'react';
import PropTypes from 'prop-types';
import './Select.css';

const Select = ({ options, value, onChange, label, ...props }) => {
  return (
    <div className="select-container">
      <select 
        value={value} 
        onChange={onChange} 
        className="select-element"
        placeholder=" "
        {...props}
      >
        <option value="" disabled hidden></option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {props.placeholder && <label className="select-label">{props.placeholder}</label>}
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Select;