import JsonBeautifier from '../pages/JSON utility/JsonBeautifier/JsonBeautifier';
import UrlEncoderDecoder from '../pages/encoder/UrlEncoderDecoder/UrlEncoderDecoder';
import Base64EncoderDecoder from '../pages/encoder/Base64EncoderDecoder/Base64EncoderDecoder';
import TimezoneConverter from '../pages/utility/TimezoneConverter/TimezoneConverter';
import RandomUuidGenerator from '../pages/utility/RandomUuidGenerator/RandomUuidGenerator';
import JwtViewerVerifier from '../pages/verifier/JwtViewerVerifier/JwtViewerVerifier';
import DateCalculator from '../pages/utility/DateCalculator/DateCalculator';
import JsonDiffChecker from '../pages/JSON utility/JsonDiffChecker/JsonDiffChecker';
import CSSMinifier from '../pages/utility/CSSMinifier/CSSMinifier';
import PDFHandler from '../pages/pdf/split/PDFHandler';
import CombinePDFs from '../pages/pdf/combine/CombinePDFs';
import CompressPDFs from '../pages/pdf/compress/CompressPDFs';
import ExtractPDFPages from '../pages/pdf/ExtractPDF/ExtractPDFPages';
import RemovePDFPages from '../pages/pdf/RemovePDF/RemovePDFPages';
import AddPageNumbersToPDF from '../pages/pdf/AddPageNumbersToPDF/AddPageNumbersToPDF';
import AddWatermarkToPDF from '../pages/pdf/AddWatermark/AddWatermarkToPDF';
import RotatePDFPages from '../pages/pdf/RotatePDF/RotatePDFPages';
import HtmlToPdf from '../pages/pdf/HtmlToPdf/HtmlToPdf';
import SignPDF from '../pages/pdf/SignPDF/SignPDF';
import UnlockPDF from '../pages/pdf/UnlockPDF/UnlockPDF';
import ImagesToPDF from '../pages/pdf/ImagesToPDF/ImagesToPDF';
import ImageToPDF from '../pages/pdf/ImageToPDF/ImageToPDF';
import LockPDF from '../pages/pdf/LockPDF/LockPDF';
import ImageCompressor from '../pages/image/ImageCompressor/ImageCompressor';
import ChangeImageFormat from '../pages/image/ChangeImageFormat/ChangeImageFormat';
import ResizeImageByPercentage from '../pages/image/ResizeImageByPercentage/ResizeImageByPercentage';
import ResizeAndConvertImage from '../pages/image/ResizeAndConvertImage/ResizeAndConvertImage';
import BulkImageResize from '../pages/image/BulkImageResize/BulkImageResize';
import ImageCropper from '../pages/image/ImageCropper/ImageCropper';
import BulkConvertImageFormat from '../pages/image/BulkConvertImageFormat/BulkConvertImageFormat';
import BulkCompressConvertImage from '../pages/image/BulkCompressConvertImage/BulkCompressConvertImage';
import JsonStringifier from '../pages/JSON utility/JsonStringifier/JsonStringifier';
import JsonUnstringifier from '../pages/JSON utility/JsonUnstringifier/JsonUnstringifier';
import JsonToJsObject from '../pages/JSON utility/JsonToJsObject/JsonToJsObject';
import ColorTools from '../pages/utility/ColorTools/ColorTools';
import RegexTester from '../pages/Regex/RegexTester/RegexTester';
import RegexLibrary from '../pages/Regex/regexLibrary/regexLibrary';
import GradientCreator from '../pages/utility/GradientCreator/GradientCreator';
import GradientSamples from '../pages/utility/GradientSamples/GradientSamples';
import ColorPicker from '../pages/utility/ColorPicker/ColorPicker';
import ImageUploader from '../pages/image/ImageUploader/ImageUploader';
import BackgroundRemover from '../pages/image/BackgroundRemover/BackgroundRemover';
import csvtojson from '../pages/JSON utility/csvtojson/csvtojson';
import json2csv from '../pages/JSON utility/jsontocsv/json2csv';
import SvgToPng from '../pages/image/SvgToPng/SvgToPng';
import ResizeImageCanvas from '../pages/image/ResizeImageCanvas/ResizeImageCanvas';


import UrlParser from '../pages/utility/UrlParser/UrlParser';
import CronJobParser from '../pages/utility/CronJobParser/CronJobParser';
import HtmlPreview from '../pages/utility/HtmlPreview/HtmlPreview';
import MarkdownPreview from '../pages/utility/MarkdownPreview/MarkdownPreview';
import JsonYamlConverter from '../pages/JSON utility/JsonYamlConverter/JsonYamlConverter';
import StringCaseConverter from '../pages/utility/StringCaseConverter/StringCaseConverter';
import ColorConverter from '../pages/utility/ColorConverter/ColorConverter';

import SvgToCss from '../pages/utility/SvgToCss/SvgToCss';
import JsonToCode from '../pages/JSON utility/JsonToCode/JsonToCode';
import HtmlToJsx from '../pages/utility/HtmlToJsx/HtmlToJsx';
import UnixTimeConverter from '../pages/utility/UnixTimeConverter/UnixTimeConverter';
import NumberBaseConverter from '../pages/utility/NumberBaseConverter/NumberBaseConverter';

import CurlToCode from '../pages/utility/CurlToCode/CurlToCode';
import HexAsciiConverter from '../pages/utility/HexAsciiConverter/HexAsciiConverter';

import HtmlBeautifyMinify from '../pages/utility/HtmlBeautifyMinify/HtmlBeautifyMinify';

import GenerateRegex from '../pages/AI/GenerateRegex';
import InstagramBio from '../pages/AI/InstagramBio';
import GenerateCSV from '../pages/AI/GenerateCSV';

const baseUrl = 'https://devessentialsbackend.onrender.com';
const routes = [
  {
    path: '/ai/generate-regex',
    component: GenerateRegex,
    apiEndpoint: `${baseUrl}/ai/generate-regex`,
    title: 'Generate Regex',
    description: 'Generate regular expressions from descriptions',
    type: 'AI',
    menuTitle: 'Regex Generator',
    howToUse: `
      1. Enter a description of the pattern you want to match in the input field.
      2. Click the "Generate Regex" button.
      3. The generated regular expression will be displayed along with an explanation.
      4. You can copy the regex to use in your code or test it with sample inputs.
    `
  },
  {
    path: '/ai/instagram-bio',
    component: InstagramBio,
    apiEndpoint: `${baseUrl}/ai/instagram-bio`,
    title: 'Instagram Bio Generator',
    description: 'Create engaging Instagram bios',
    type: 'AI',
    menuTitle: 'Instagram Bio',
    howToUse: `
      1. Enter keywords or a brief description of yourself or your brand.
      2. Click "Generate Bio" to create an engaging Instagram bio.
      3. The generated bio and suggested hashtags will be displayed.
      4. You can regenerate or edit the bio as needed before using it on Instagram.
    `
  },
  {
    path: '/ai/generate-csv',
    component: GenerateCSV,
    apiEndpoint: `${baseUrl}/ai/generate-csv`,
    title: 'CSV Generator',
    description: 'Generate CSV mock data based on your specifications',
    type: 'AI',
    menuTitle: 'CSV Generator',
    howToUse:  `1. In the text area, describe the type of CSV data you want to generate. Include the context or scenario and the column names you want in your CSV.
2. Example template: "Create a CSV for a logistics company called FastlyTrack Shipping. Include columns 'Order ID', 'Customer Name', 'Shipping Address', 'Package Weight (kg)', 'Delivery Status', 'Estimated Delivery Date', 'Actual Delivery Date', 'Customer Feedback', 'Delivery Person', 'Shipping Cost ($)'"
3. Specify the number of rows you want to generate (1-1000).
4. Click the "Generate CSV" button.
5. A preview of the generated CSV will be displayed, showing how the AI has created varied, context-appropriate content based on your description.
6. Click the "Download CSV" button to save the full generated CSV file.
7. You can then open this CSV file in a spreadsheet application to view the complete set of generated data.
8. This tool is versatile and can be used for various scenarios such as logistics management simulation, inventory tracking, or any other situation where you need structured, varied data for analysis or testing purposes.`


  },
  {
    path: '/html-beautify-minify',
    title: 'HTML Beautify/Minify - Format and Compress HTML Code',
    description: 'Easily beautify or minify your HTML code with our online tool. Improve readability or reduce file size for better performance.',
    component: HtmlBeautifyMinify,
    type: 'utility',
    menuTitle: 'HTML Beautify/Minify',
    howToUse: `
    1. Paste your HTML code into the input text area.
    2. Click the "Beautify" button to format your HTML with proper indentation and line breaks.
    3. Click the "Minify" button to compress your HTML by removing unnecessary whitespace.
    4. The processed HTML will appear in the output text area.
    5. Click "Copy to Clipboard" to copy the result.
    6. You can make further edits to the input and re-process as needed.
  `
  },
  {
    path: '/curl-to-code',
    title: 'cURL to Code Converter',
    description: 'Convert cURL commands to various programming languages',
    component: CurlToCode,
    type: 'utility',
    menuTitle: 'cURL to Code',
    howToUse: `
    1. Enter your cURL command in the input field.
    2. Select the target programming language from the dropdown menu.
    3. Click the "Convert" button.
    4. The equivalent code in the selected language will appear in the output area.
    5. Use the "Copy" button to copy the converted code to your clipboard.
  `
  },
  {
    path: '/hex-ascii-converter',
    title: 'Hex to ASCII / ASCII to Hex Converter',
    description: 'Convert between hexadecimal and ASCII representations',
    component: HexAsciiConverter,
    type: 'utility',
    menuTitle: 'Hex/ASCII Converter',
    howToUse: `
      1. Choose the conversion direction: Hex to ASCII or ASCII to Hex.
      2. For Hex to ASCII:
         - Enter the hexadecimal values in the input field (e.g., 48 65 6C 6C 6F).
         - Click "Convert to ASCII" to see the result.
      3. For ASCII to Hex:
         - Enter the ASCII text in the input field (e.g., Hello).
         - Click "Convert to Hex" to see the result.
      4. The converted output will appear in the result area.
      5. Use the "Copy" button to copy the result to your clipboard.
      6. You can toggle between space-separated and continuous output formats.
    `
  },
  {
    path: '/svg-to-css',
    title: 'SVG to CSS Converter',
    description: 'Convert SVG paths to CSS shapes',
    component: SvgToCss,
    type: 'utility',
    menuTitle: 'SVG to CSS',
    howToUse: `
      1. Paste your SVG path data into the input field (e.g., M10 10 H 90 V 90 H 10 L 10 10).
      2. Set the desired width and height for your CSS shape.
      3. Click the "Convert" button.
      4. The tool will generate CSS code for creating the shape using clip-path or background-image.
      5. Copy the generated CSS code and use it in your stylesheets.
      6. You can preview the shape in the output area to ensure it matches your expectations.
      7. Adjust the input SVG or dimensions as needed and reconvert.
    `
  },
  {
    path: '/json-to-code',
    title: 'JSON to Code Converter',
    description: 'Convert JSON to various programming language objects',
    component: JsonToCode,
    type: 'JSON utility',
    menuTitle: 'JSON to Code',
    howToUse: `
      1. Paste your JSON data into the input text area.
      2. Select the target programming language from the dropdown (e.g., JavaScript, Python, Java).
      3. Click the "Convert" button.
      4. The tool will generate code in the selected language that creates an object structure matching your JSON.
      5. Review the generated code in the output area.
      6. Use the "Copy" button to copy the code to your clipboard.
      7. You can modify the input JSON and reconvert as needed.
      8. If there are any JSON parsing errors, they will be displayed below the input area.
    `
  },
  {
    path: '/html-to-jsx',
    title: 'HTML to JSX Converter',
    description: 'Convert HTML to JSX for React applications',
    component: HtmlToJsx,
    type: 'utility',
    menuTitle: 'HTML to JSX',
    howToUse: `
      1. Paste your HTML code into the input text area.
      2. Click the "Convert to JSX" button.
      3. The tool will transform the HTML into valid JSX, making necessary adjustments like:
         - Changing 'class' attributes to 'className'
         - Updating 'for' attributes to 'htmlFor'
         - Self-closing tags like <img> to <img />
      4. Review the converted JSX in the output area.
      5. Use the "Copy JSX" button to copy the result to your clipboard.
      6. You can make changes to the input HTML and reconvert as needed.
      7. The tool will also provide warnings for potential issues in React components.
    `
  },
  {
    path: '/unix-time-converter',
    title: 'Unix Time Converter',
    description: 'Convert between Unix timestamps and human-readable dates',
    component: UnixTimeConverter,
    type: 'utility',
    menuTitle: 'Unix Time',
    howToUse: `
      1. To convert from Unix timestamp to date:
         - Enter the Unix timestamp in the input field (e.g., 1609459200).
         - Click "Convert to Date".
         - The human-readable date and time will be displayed.
      2. To convert from date to Unix timestamp:
         - Use the date and time pickers to select your desired date and time.
         - Click "Convert to Unix Time".
         - The corresponding Unix timestamp will be shown.
      3. The current Unix timestamp is displayed and updates in real-time.
      4. Use the "Copy" buttons to copy results to your clipboard.
      5. You can toggle between different time zones for conversion.
    `
  },
  {
    path: '/number-base-converter',
    title: 'Number Base Converter',
    description: 'Convert numbers between different bases (binary, decimal, hexadecimal, etc.)',
    component: NumberBaseConverter,
    type: 'utility',
    menuTitle: 'Base Converter',
    howToUse: `
      1. Enter the number you want to convert in the input field.
      2. Select the base of your input number from the dropdown (e.g., Decimal, Binary, Hexadecimal).
      3. Choose the target base you want to convert to from the second dropdown.
      4. Click the "Convert" button.
      5. The converted number will be displayed in the result area.
      6. You can convert between bases 2 to 36, including common bases like:
         - Binary (Base 2)
         - Octal (Base 8)
         - Decimal (Base 10)
         - Hexadecimal (Base 16)
      7. Use the "Swap" button to quickly switch between input and output bases.
      8. The tool will validate your input to ensure it's a valid number in the selected base.
    `
  },
  {
    path: '/markdown-preview',
    title: 'Markdown Preview',
    description: 'Preview and edit Markdown in real-time',
    component: MarkdownPreview,
    type: 'utility',
    menuTitle: 'Markdown Preview',
    howToUse: `
      1. Enter or paste your Markdown text in the left pane.
      2. As you type or edit, the rendered HTML preview will update in real-time on the right pane.
      3. Use the toolbar buttons for quick formatting options like bold, italic, lists, etc.
      4. You can toggle between split view and full-screen edit/preview modes.
      5. Use the "Copy HTML" button to copy the generated HTML to your clipboard.
      6. The "Download" button allows you to save your Markdown as a .md file.
      7. You can also upload existing Markdown files using the "Upload" button.
      8. Syntax highlighting for code blocks is supported - just specify the language after the opening backticks.
    `
  },
  {
    path: '/json-yaml-converter',
    title: 'JSON to YAML / YAML to JSON Converter',
    description: 'Convert between JSON and YAML formats',
    component: JsonYamlConverter,
    type: 'JSON utility',
    menuTitle: 'JSON ⇄ YAML',
    howToUse: `
      1. Choose the conversion direction: JSON to YAML or YAML to JSON.
      2. For JSON to YAML conversion:
         - Paste your JSON data into the input field.
         - Click "Convert to YAML".
      3. For YAML to JSON conversion:
         - Paste your YAML data into the input field.
         - Click "Convert to JSON".
      4. The converted output will appear in the result area.
      5. Use the "Copy" button to copy the result to your clipboard.
      6. The tool will validate your input and show any parsing errors.
      7. You can toggle between compact and pretty-printed output for JSON.
      8. For YAML output, you can adjust the indentation level.
    `
  },
  {
    path: '/string-case-converter',
    title: 'String Case Converter',
    description: 'Convert text between different cases (camelCase, snake_case, etc.)',
    component: StringCaseConverter,
    type: 'utility',
    menuTitle: 'Case Converter',
    howToUse: `
      1. Enter or paste your text in the input field.
      2. Select the target case from the dropdown menu. Options include:
         - camelCase
         - PascalCase
         - snake_case
         - kebab-case
         - UPPER_CASE_SNAKE
         - Title Case
         - Sentence case
         - lower case
         - UPPER CASE
      3. Click the "Convert" button.
      4. The converted text will appear in the output field.
      5. Use the "Copy" button to copy the result to your clipboard.
      6. You can convert multiple lines of text at once.
      7. The tool will preserve numbers and special characters in your text.
      8. Use the "Clear" button to reset both input and output fields.
    `
  },
  {
    path: '/color-converter',
    title: 'Color Converter',
    description: 'Convert colors between different formats (HEX, RGB, HSL, etc.)',
    component: ColorConverter,
    type: 'utility',
    menuTitle: 'Color Converter',
    howToUse: `
      1. Enter a color value in any supported format (HEX, RGB, HSL, HSV, CMYK, or color name).
      2. The tool will automatically detect the input format and convert it to all other formats.
      3. You'll see the color displayed in a preview box.
      4. Converted values will be shown for:
         - HEX (#RRGGBB)
         - RGB (Red, Green, Blue)
         - HSL (Hue, Saturation, Lightness)
         - HSV (Hue, Saturation, Value)
         - CMYK (Cyan, Magenta, Yellow, Key)
         - Color name (if applicable)
      5. Use the color picker to visually select a color.
      6. Adjust individual color components using sliders.
      7. Copy any converted value by clicking the "Copy" button next to it.
      8. The tool also provides complementary and analogous colors for design inspiration.
    `
  },
  {
    path: '/html-preview',
    title: 'HTML Preview - Safe HTML Rendering',
    description: 'Safely preview HTML code with protection against XSS attacks and JavaScript injection.',
    component: HtmlPreview,
    type: 'utility',
    menuTitle: 'HTML Preview',
    howToUse: `
      1. Enter or paste your HTML code in the input text area.
      2. The preview will update in real-time as you type or edit.
      3. The tool sanitizes the HTML to prevent XSS attacks and script injection:
         - Removes potentially harmful tags and attributes
         - Disables JavaScript execution
         - Sanitizes URLs in attributes
      4. Use the "Toggle View" button to switch between code and preview modes.
      5. The "Copy HTML" button allows you to copy the sanitized HTML.
      6. You can include CSS styles within <style> tags for formatting.
      7. External resources (images, stylesheets) are allowed but may be blocked by your browser's security settings.
      8. The tool provides warnings for any potentially unsafe elements that were removed during sanitization.
    `
  },
  {
    path: '/url-parser',
    title: 'URL Parser - Decode and Analyze URLs',
    description: 'Parse and analyze URL components with our easy-to-use URL Parser tool.',
    component: UrlParser,
    type: 'utility',
    menuTitle: 'URL Parser',
    howToUse: `
      1. Enter a complete URL in the input field (e.g., https://www.example.com/path?param1=value1&param2=value2).
      2. Click the "Parse URL" button.
      3. The tool will break down the URL into its components:
         - Protocol (e.g., http, https)
         - Hostname
         - Port (if specified)
         - Path
         - Query parameters
         - Fragment (if present)
      4. Each component will be displayed separately for easy analysis.
      5. Use the "Copy" buttons next to each component to copy individual parts.
      6. The tool also provides a URL-decoded version of the query parameters.
      7. You can edit the URL and re-parse it to see updated results.
      8. Use the "Encode" and "Decode" buttons to URL-encode or decode specific parts of the URL.
    `
  },
  {
    path: '/cron-job-parser',
    title: 'Cron Job Parser - Decode Cron Expressions',
    description: 'Easily parse and understand cron job expressions with our Cron Job Parser tool.',
    component: CronJobParser,
    type: 'utility',
    menuTitle: 'Cron Job Parser',
    howToUse: `
      1. Enter a cron expression in the input field (e.g., 0 0 * * *).
      2. Click the "Parse Cron" button.
      3. The tool will interpret the cron expression and provide:
         - A human-readable description of when the job will run
         - The next 5 execution dates and times
         - Breakdown of each field (minute, hour, day of month, month, day of week)
      4. Use the dropdown menus to select predefined common cron expressions.
      5. The tool supports standard cron format as well as some extended syntax.
      6. You can use the "Validate" button to check if your cron expression is valid.
      7. The "Generate" feature helps you create cron expressions based on your requirements.
      8. Use the "Copy" button to copy the parsed description or the original cron expression.
    `
  },
  {
    path: '/pdf-handler',
    title: 'PDF Handler - Manage Your PDF Files',
    description: 'Easily view, merge, split, and convert PDF files online with our PDF Handler tool.',
    component: PDFHandler,
    type: 'PDF utility',
    menuTitle: 'PDF Split',
    howToUse: `
      1. Click the "Upload PDF" button to select a PDF file from your device.
      2. Once uploaded, you'll see a preview of all pages in the PDF.
      3. To split the PDF:
         - Select the pages you want to extract by clicking on them (they will be highlighted).
         - Click the "Split PDF" button.
         - The selected pages will be extracted into a new PDF.
      4. To merge PDFs:
         - Upload multiple PDF files using the "Upload PDF" button.
         - Arrange the order of PDFs by dragging and dropping the thumbnails.
         - Click the "Merge PDFs" button to combine them into a single PDF.
      5. Use the "Download" button to save the resulting PDF to your device.
      6. You can also use the "Delete" button to remove any uploaded PDF from the list.
      7. The "Preview" button allows you to view the full PDF before processing.
      8. For large PDFs, use the pagination controls to navigate through the pages.
    `
  },
  {
    path: '/combine-pdfs',
    title: 'Combine PDFs - Merge Multiple PDF Files',
    description: 'Easily merge multiple PDF files into one document with our Combine PDFs tool.',
    component: CombinePDFs,
    type: 'PDF utility',
    menuTitle: 'Combine PDFs',
    howToUse: `
      1. Click the "Add PDFs" button to select multiple PDF files from your device.
      2. The selected PDFs will appear as thumbnails in the order they were added.
      3. You can rearrange the order of PDFs by dragging and dropping the thumbnails.
      4. To remove a PDF from the list, click the "X" button on its thumbnail.
      5. Once you're satisfied with the order, click the "Combine PDFs" button.
      6. The tool will merge all selected PDFs into a single document.
      7. After processing, use the "Download Combined PDF" button to save the result.
      8. You can preview the combined PDF before downloading using the "Preview" button.
      9. The tool supports combining up to 20 PDFs at once (or a total of 100 MB).
    `
  },
  {
    path: '/resize-image-canvas',
    title: 'Resize Image Canvas',
    description: 'Resize the canvas of an image without changing its content.',
    component: ResizeImageCanvas,
    type: 'Image utility',
    menuTitle: 'Resize Canvas',
    howToUse: `
      1. Click the "Upload Image" button to select an image file from your device.
      2. Once uploaded, you'll see a preview of your image.
      3. Enter the new canvas dimensions in the "Width" and "Height" fields.
      4. Choose the anchor point for the image content (e.g., center, top-left, bottom-right).
      5. Select a background color for any new space added to the canvas.
      6. Click the "Resize Canvas" button to apply the changes.
      7. You'll see a preview of the resized image with the new canvas dimensions.
      8. Use the "Download" button to save the resized image to your device.
      9. You can adjust the settings and resize multiple times before downloading.
      10. The tool supports common image formats like JPG, PNG, and GIF.
    `
  },
  {
    path: '/compress-pdfs',
    title: 'Compress PDFs',
    description: 'Reduce the size of your PDF files with our easy-to-use PDF compression tool.',
    component: CompressPDFs,
    type: 'PDF utility',
    menuTitle: 'Compress PDF',
    howToUse: `
      1. Click the "Upload PDF" button to select a PDF file from your device.
      2. Once uploaded, you'll see the original file size of your PDF.
      3. Choose a compression level:
         - Low: Minimal quality loss, smaller file size reduction
         - Medium: Balanced between quality and file size reduction
         - High: Maximum file size reduction, may affect quality
      4. Click the "Compress PDF" button to start the compression process.
      5. After compression, you'll see the new file size and the percentage of reduction.
      6. Use the "Download Compressed PDF" button to save the result to your device.
      7. You can preview both the original and compressed PDFs using the "Preview" buttons.
      8. The tool allows you to compress multiple PDFs in batch mode.
      9. For large PDFs, the tool may take a few moments to process. Please be patient.
    `
  },
  {
    path: '/extract-pdf-pages',
    title: 'Extract PDF Pages',
    description: 'Extract specific pages from your PDF documents easily.',
    component: ExtractPDFPages,
    type: 'PDF utility',
    menuTitle: 'Extract Pages',
    howToUse: `
      1. Click the "Upload PDF" button to select a PDF file from your device.
      2. Once uploaded, you'll see thumbnails of all pages in the PDF.
      3. Select the pages you want to extract by clicking on their thumbnails.
      4. You can also use the "Select Range" feature to quickly select multiple pages:
         - Enter the start and end page numbers
         - Click "Select Range" to highlight those pages
      5. The "Select All" and "Deselect All" buttons help manage your selection.
      6. Once you've chosen the desired pages, click the "Extract Pages" button.
      7. The tool will create a new PDF containing only the selected pages.
      8. Use the "Download Extracted PDF" button to save the result to your device.
      9. You can preview the extracted PDF before downloading using the "Preview" button.
      10. The original PDF remains unchanged; you're creating a new PDF with selected pages.
    `
  },
  {
    path: '/remove-pdf-pages',
    title: 'Remove PDF Pages',
    description: 'Easily remove specific pages from your PDF documents.',
    component: RemovePDFPages,
    type: 'PDF utility',
    menuTitle: 'Remove Pages',
    howToUse: `
      1. Click the "Upload PDF" button to select a PDF file from your device.
      2. Once uploaded, you'll see thumbnails of all pages in the PDF.
      3. Select the pages you want to remove by clicking on their thumbnails.
      4. You can also use the "Select Range" feature to quickly select multiple pages:
         - Enter the start and end page numbers
         - Click "Select Range" to highlight those pages
      5. The "Select All" and "Deselect All" buttons help manage your selection.
      6. Once you've chosen the pages to remove, click the "Remove Pages" button.
      7. The tool will create a new PDF with the selected pages removed.
      8. Use the "Download Modified PDF" button to save the result to your device.
      9. You can preview the modified PDF before downloading using the "Preview" button.
      10. The original PDF remains unchanged; you're creating a new PDF without the selected pages.
    `
  },
  {
    path: '/add-page-numbers',
    title: 'Add Page Numbers to PDF',
    description: 'Easily add page numbers to your PDF documents.',
    component: AddPageNumbersToPDF,
    type: 'PDF utility',
    menuTitle: 'Add Page Numbers',
    howToUse: `
      1. Click the "Upload PDF" button to select a PDF file from your device.
      2. Once uploaded, you'll see a preview of the first page of your PDF.
      3. Choose the position for page numbers:
         - Bottom Right
         - Bottom Center
         - Bottom Left
         - Top Right
         - Top Center
         - Top Left
      4. Select the starting page number (useful for documents with cover pages).
      5. Choose the font style and size for the page numbers.
      6. Optionally, you can add a prefix or suffix to the page numbers (e.g., "Page - ").
      7. Click the "Add Page Numbers" button to process the PDF.
      8. After processing, you can preview the result with the added page numbers.
      9. Use the "Download Numbered PDF" button to save the modified PDF to your device.
      10. You can adjust the settings and reprocess the PDF multiple times if needed.
    `
  },
  {
    path: '/add-watermark',
    title: 'Add Watermark to PDF',
    description: 'Easily add watermarks to your PDF documents.',
    component: AddWatermarkToPDF,
    type: 'PDF utility',
    menuTitle: 'Add Watermark',
    howToUse: `
      1. Click the "Upload PDF" button to select a PDF file from your device.
      2. Once uploaded, you'll see a preview of the first page of your PDF.
      3. Choose the type of watermark:
         - Text Watermark: Enter the text you want to use as a watermark
         - Image Watermark: Upload an image file to use as a watermark
      4. For text watermarks:
         - Enter the watermark text
         - Choose the font, size, and color
      5. For image watermarks:
         - Upload the image file
         - Adjust the opacity and size
      6. Set the position of the watermark (e.g., center, top-left, bottom-right).
      7. Choose whether to apply the watermark to all pages or specific pages.
      8. Click the "Add Watermark" button to process the PDF.
      9. After processing, you can preview the result with the added watermark.
      10. Use the "Download Watermarked PDF" button to save the modified PDF to your device.
    `
  },
  {
    path: '/rotate-pdf-pages',
    title: 'Rotate PDF Pages',
    description: 'Rotate specific pages in your PDF documents.',
    component: RotatePDFPages,
    type: 'PDF utility',
    menuTitle: 'Rotate Pages',
    howToUse: `
      1. Click the "Upload PDF" button to select a PDF file from your device.
      2. Once uploaded, you'll see thumbnails of all pages in the PDF.
      3. For each page, you can choose the rotation angle:
         - 90° clockwise
         - 90° counterclockwise
         - 180°
      4. Click on the rotation buttons next to each page thumbnail to apply rotation.
      5. You can rotate multiple pages in different directions.
      6. To rotate all pages at once, use the "Rotate All" dropdown and select an angle.
      7. Click the "Apply Rotations" button to process the PDF.
      8. After processing, you can preview the result with the rotated pages.
      9. Use the "Download Rotated PDF" button to save the modified PDF to your device.
      10. You can reset all rotations using the "Reset All" button if you make a mistake.
    `
  },
  {
    path: '/html-to-pdf',
    title: 'HTML to PDF Conversion',
    description: 'Convert HTML content to a PDF document with customizable options.',
    component: HtmlToPdf,
    type: 'PDF utility',
    menuTitle: 'HTML to PDF',
    howToUse: `
      1. Enter your HTML content in the text area provided.
      2. You can also use the "Upload HTML File" button to load an HTML file from your device.
      3. Customize the PDF output options:
         - Page size (A4, Letter, etc.)
         - Orientation (Portrait or Landscape)
         - Margins (Top, Bottom, Left, Right)
         - Header and Footer content (optional)
      4. If your HTML references external CSS, you can include it in the "Custom CSS" section.
      5. Click the "Convert to PDF" button to process your HTML.
      6. The tool will generate a PDF based on your HTML content and settings.
      7. After conversion, you can preview the generated PDF in the browser.
      8. Use the "Download PDF" button to save the result to your device.
      9. If you need to make changes, you can edit the HTML and convert again.
      10. For complex layouts, you may need to adjust your HTML/CSS for optimal PDF output.
    `
  },
  {
    path: '/sign-pdf',
    title: 'Sign PDF',
    description: 'Sign your PDF documents with custom text and position.',
    component: SignPDF,
    type: 'PDF utility',
    menuTitle: 'Sign PDF',
    howToUse: `
      9. You can add multiple signatures to different pages if needed.
      10. Click the "Finalize and Download" button to save the signed PDF to your device.
      11. You can preview the final signed PDF before downloading.
      12. Note that this digital signature is for visual purposes and not legally binding.
    `
  },
  {
    path: '/unlock-pdf',
    title: 'Unlock PDF',
    description: 'Unlock your password-protected PDF documents.',
    component: UnlockPDF,
    type: 'PDF utility',
    menuTitle: 'Unlock PDF',
    howToUse: `
      1. Click the "Upload PDF" button to select a password-protected PDF file.
      2. Enter the password for the PDF in the provided field.
      3. Click the "Unlock PDF" button to remove the password protection.
      4. If the password is correct, the tool will process the PDF and remove the encryption.
      5. Once unlocked, you can preview the first page of the PDF to confirm it's the correct document.
      6. Use the "Download Unlocked PDF" button to save the unlocked version to your device.
      7. If the password is incorrect, you'll see an error message and can try again.
      8. This tool only removes password protection; it cannot bypass or crack unknown passwords.
      9. The original PDF remains unchanged; you're creating a new, unlocked copy.
      10. For security reasons, the unlocked PDF is not stored on our servers after your session.
    `
  },
  {
    path: '/images-to-pdf',
    title: 'Convert Images to PDF',
    description: 'Easily convert your images into a single PDF document.',
    component: ImagesToPDF,
    type: 'PDF utility',
    menuTitle: 'Multiple Images to PDF',
    howToUse: `
      1. Click the "Upload Images" button to select multiple image files from your device.
      2. Supported formats include JPG, PNG, GIF, and BMP.
      3. The uploaded images will appear as thumbnails in the order they were added.
      4. You can rearrange the order of images by dragging and dropping the thumbnails.
      5. To remove an image, click the "X" button on its thumbnail.
      6. Choose the page size for your PDF (e.g., A4, Letter, Custom).
      7. Select the orientation (Portrait or Landscape) for the PDF pages.
      8. Optionally, you can set margins and adjust image scaling options.
      9. Click the "Convert to PDF" button to create your PDF.
      10. After processing, use the "Download PDF" button to save the result to your device.
      11. You can preview the generated PDF before downloading.
      12. The tool allows conversion of up to 20 images at once (or a total of 50 MB).
    `
  },
  {
    path: '/image-to-pdf',
    title: 'Convert Images to PDF',
    description: 'Easily convert your image into a PDF document.',
    component: ImageToPDF,
    type: 'PDF utility',
    menuTitle: 'Single Image to PDF',
    howToUse: `
      1. Click the "Upload Image" button to select an image file from your device.
      2. Supported formats include JPG, PNG, GIF, and BMP.
      3. Once uploaded, you'll see a preview of your image.
      4. Choose the page size for your PDF (e.g., A4, Letter, Custom).
      5. Select the orientation (Portrait or Landscape) for the PDF page.
      6. Adjust the image scaling options:
         - Fit to page
         - Stretch to fill
         - Maintain original size
      7. Optionally, you can set margins for the PDF page.
      8. Click the "Convert to PDF" button to create your PDF.
      9. After processing, use the "Download PDF" button to save the result to your device.
      10. You can preview the generated PDF before downloading.
      11. This tool is ideal for quickly creating a PDF from a single image file.
    `
  },
  {
    path: '/lock-pdf',
    title: 'Lock PDF',
    description: 'Secure your PDF documents with a password.',
    component: LockPDF,
    type: 'PDF utility',
    menuTitle: 'Lock PDF',
    howToUse: `
      1. Click the "Upload PDF" button to select a PDF file from your device.
      2. Once uploaded, you'll see a preview of the first page of your PDF.
      3. Enter a strong password in the "Set Password" field.
      4. Confirm the password by entering it again in the "Confirm Password" field.
      5. Choose the encryption level:
         - 128-bit AES (recommended for most uses)
         - 256-bit AES (highest security, but may not be supported by all PDF readers)
      6. Optionally, you can set permissions for the protected PDF:
         - Allow/disallow printing
         - Allow/disallow editing
         - Allow/disallow copying text and images
      7. Click the "Lock PDF" button to apply the password protection.
      8. After processing, use the "Download Protected PDF" button to save the result.
      9. You can preview the locked PDF to ensure it's protected (you'll need to enter the password).
      10. Remember to securely store the password you've set, as it cannot be recovered if lost.
    `
  },
  {
    path: '/image-compressor',
    title: 'Image Compressor',
    description: 'Compress images with various output format options.',
    component: ImageCompressor,
    type: 'Image utility',
    menuTitle: 'Image Compressor',
    howToUse: `
      1. Click the "Upload Image" button to select an image file from your device.
      2. Supported input formats include JPG, PNG, GIF, and WebP.
      3. Once uploaded, you'll see a preview of your image and its current file size.
      4. Choose the desired output format:
         - JPG (best for photographs)
         - PNG (best for graphics with transparency)
         - WebP (modern format with good compression)
      5. Adjust the compression level using the slider:
         - Lower values result in smaller file sizes but may reduce quality
         - Higher values maintain better quality but result in larger file sizes
      6. You can see a live preview of the compressed image quality.
      7. The tool displays the estimated file size of the compressed image.
      8. Click the "Compress Image" button to process your image.
      9. After compression, compare the original and compressed file sizes.
      10. Use the "Download Compressed Image" button to save the result to your device.
      11. You can compress multiple images in batch by clicking "Add More Images".
    `
  },
  {
    path: '/change-image-format',
    title: 'Change Image Format',
    description: 'Convert the format of your images easily.',
    component: ChangeImageFormat,
    type: 'Image utility',
    menuTitle: 'Image Format',
    howToUse: `
      1. Click the "Upload Image" button to select an image file from your device.
      2. Supported input formats include JPG, PNG, GIF, WebP, and TIFF.
      3. Once uploaded, you'll see a preview of your image and its current format.
      4. Choose the desired output format from the dropdown menu:
         - JPG
         - PNG
         - WebP
         - TIFF
         - GIF
      5. Adjust any format-specific settings:
         - For JPG: Set the quality level (0-100)
         - For PNG: Choose the compression level
         - For WebP: Set the quality level
         - For TIFF: Select the compression method
      6. If converting to PNG or GIF, you can choose to preserve transparency.
      7. Click the "Convert Image" button to process your image.
      8. After conversion, you'll see a preview of the converted image.
      9. Compare the original and converted file sizes.
      10. Use the "Download Converted Image" button to save the result to your device.
      11. You can convert multiple images in batch by clicking "Add More Images".
    `
  },
  {
    path: '/resize-image-percentage',
    title: 'Resize Image By Percentage',
    description: 'Resize your images by a specific percentage.',
    component: ResizeImageByPercentage,
    type: 'Image utility',
    menuTitle: 'Resize Image',
    howToUse: `
      1. Click the "Upload Image" button to select an image file from your device.
      2. Once uploaded, you'll see a preview of your original image and its dimensions.
      3. Enter the desired resize percentage in the input field (e.g., 50 for 50% of original size).
      4. You can resize up (values over 100%) or down (values under 100%).
      5. As you adjust the percentage, you'll see a live preview of the resized image.
      6. The tool will display the new dimensions based on your percentage input.
      7. Choose whether to maintain the aspect ratio (recommended) or allow distortion.
      8. Select the output format (JPG, PNG, WebP) for the resized image.
      9. Click the "Resize Image" button to process your image.
      10. After resizing, use the "Download Resized Image" button to save the result.
      11. You can upload a new image or adjust the percentage to resize again.
    `
  },
  {
    path: '/resize-convert-image',
    title: 'Resize and Convert Image',
    description: 'Resize and convert the format of your images.',
    component: ResizeAndConvertImage,
    type: 'Image utility',
    menuTitle: 'Resize & Convert',
    howToUse: `
      1. Click the "Upload Image" button to select an image file from your device.
      2. Once uploaded, you'll see a preview of your original image and its details.
      3. Enter the desired width and height for resizing:
         - You can enter one dimension and the other will adjust to maintain aspect ratio
         - Or enter both for custom dimensions (may distort the image)
      4. Choose the output format from the dropdown (JPG, PNG, WebP, TIFF).
      5. Adjust additional settings based on the chosen format:
         - JPG: Set quality level (0-100)
         - PNG: Choose compression level
         - WebP: Set quality level
         - TIFF: Select compression method
      6. Click the "Resize and Convert" button to process your image.
      7. After processing, you'll see a preview of the resized and converted image.
      8. Use the "Download" button to save the resulting image to your device.
      9. You can upload a new image or adjust settings to process again.
    `
  },
  {
    path: '/bulk-image-resize',
    title: 'Bulk Image Resize',
    description: 'Resize multiple images simultaneously to a specified width and height.',
    component: BulkImageResize,
    type: 'Image utility',
    menuTitle: 'Bulk Resize',
    howToUse: `
      1. Click the "Upload Images" button to select multiple image files from your device.
      2. You can also drag and drop images into the designated area.
      3. Uploaded images will appear as thumbnails with their original dimensions.
      4. Enter the desired width and height for resizing all images:
         - You can enter one dimension and the other will adjust to maintain aspect ratio
         - Or enter both for custom dimensions (may distort images)
      5. Choose whether to maintain aspect ratio for all images (recommended).
      6. Select the output format (JPG, PNG, WebP) for the resized images.
      7. Adjust quality settings if applicable to the chosen format.
      8. Click the "Resize All Images" button to process your images.
      9. The tool will display progress as it resizes each image.
      10. Once complete, you can download all resized images as a zip file.
      11. Alternatively, you can download each resized image individually.
      12. The tool supports resizing up to 50 images at once or a total of 100 MB.
    `
  },
  {
    path: '/bulk-convert-image-format',
    title: 'Bulk Convert Image Format',
    description: 'Convert multiple images to a specified format.',
    component: BulkConvertImageFormat,
    type: 'Image utility',
    menuTitle: 'Bulk Convert Format',
    howToUse: `
      1. Click the "Upload Images" button to select multiple image files from your device.
      2. You can also drag and drop images into the designated area.
      3. Uploaded images will appear as thumbnails with their current format displayed.
      4. Choose the target format from the dropdown (JPG, PNG, WebP, TIFF, GIF).
      5. Adjust format-specific settings:
         - JPG: Set quality level (0-100)
         - PNG: Choose compression level
         - WebP: Set quality level
         - TIFF: Select compression method
         - GIF: Choose between animated or static (for multi-frame images)
      6. Click the "Convert All Images" button to process your images.
      7. The tool will display progress as it converts each image.
      8. Once complete, you can download all converted images as a zip file.
      9. Alternatively, you can download each converted image individually.
      10. The original images remain unchanged; you're creating new, converted copies.
      11. The tool supports converting up to 50 images at once or a total of 100 MB.
    `
  },
  {
    path: '/bulk-compress-convert-image',
    title: 'Bulk Compress and Convert Image',
    description: 'Compress and convert multiple images to a specified format with desired quality.',
    component: BulkCompressConvertImage,
    type: 'Image utility',
    menuTitle: 'Bulk Compress & Convert',
    howToUse: `
      1. Click the "Upload Images" button to select multiple image files from your device.
      2. You can also drag and drop images into the designated area.
      3. Uploaded images will appear as thumbnails with their current size and format.
      4. Choose the target format from the dropdown (JPG, PNG, WebP).
      5. Adjust the compression level using the slider:
         - Lower values result in smaller file sizes but may reduce quality
         - Higher values maintain better quality but result in larger file sizes
      6. You can set a maximum file size limit for each compressed image.
      7. Click the "Compress and Convert" button to process your images.
      8. The tool will display progress as it compresses and converts each image.
      9. Once complete, you'll see the original and new file sizes for comparison.
      10. You can download all processed images as a zip file.
      11. Alternatively, you can download each processed image individually.
      12. The tool supports processing up to 50 images at once or a total of 100 MB.
    `
  },
  {
    path: '/image-cropper',
    title: 'Image Cropper',
    description: 'Upload and crop images interactively.',
    component: ImageCropper,
    type: 'Image utility',
    menuTitle: 'Image Cropper',
    howToUse: `
      1. Click the "Upload Image" button to select an image file from your device.
      2. Once uploaded, you'll see your image in the cropping interface.
      3. Use your mouse to drag and resize the crop area on the image.
      4. You can also enter exact dimensions or aspect ratio in the input fields.
      5. Choose a preset aspect ratio (e.g., 1:1, 16:9) or enter a custom ratio.
      6. Adjust additional options:
         - Rotate the image (90°, 180°, 270°)
         - Flip horizontally or vertically
         - Zoom in or out
      7. As you make adjustments, you'll see a live preview of the cropped area.
      8. Click the "Crop Image" button when you're satisfied with the selection.
      9. After cropping, you'll see a preview of the cropped image.
      10. Use the "Download Cropped Image" button to save the result to your device.
      11. You can upload a new image or adjust the crop area to crop again.
    `
  },
  {
    path: '/image-uploader',
    title: 'Image Uploader',
    menuTitle: 'Image Uploader',
    description: 'Upload images and get a shareable link.',
    component: ImageUploader,
    type: 'Image utility',
    howToUse: `
      1. Click the "Choose Image" button or drag and drop an image into the designated area.
      2. Select the image file you want to upload from your device.
      3. The tool will display a preview of the selected image.
      4. Optionally, you can add a title or description for your image.
      5. Click the "Upload Image" button to start the upload process.
      6. The tool will show a progress bar during the upload.
      7. Once the upload is complete, you'll receive a shareable link to your image.
      8. You can copy this link to your clipboard using the "Copy Link" button.
      9. The tool also provides options to:
         - View the uploaded image in full size
         - Get embed codes for websites or forums
         - Delete the uploaded image if needed
      10. You can upload multiple images by repeating the process.
      11. Note: Uploaded images are stored temporarily and may be removed after a certain period.
    `
  },
  {
    path: '/background-remover',
    title: 'Background Remover',
    menuTitle: 'Background Remover',
    description: 'Remove the background from images and get a processed image link.',
    component: BackgroundRemover,
    type: 'Image utility',
    howToUse: `
      1. Click the "Upload Image" button to select an image file from your device.
      2. Once uploaded, you'll see a preview of your original image.
      3. Click the "Remove Background" button to start the process.
      4. The tool will use AI to automatically detect and remove the background.
      5. Once processing is complete, you'll see the result with the background removed.
      6. You can fine-tune the result using the following options:
         - Adjust sensitivity to refine edge detection
         - Use the brush tool to manually touch up areas if needed
      7. Choose the output format for your image (PNG with transparency or JPG with white background).
      8. Click the "Download" button to save the processed image to your device.
      9. You can also use the "Get Link" button to receive a shareable link to the processed image.
      10. To process another image, simply upload a new one and repeat the steps.
      11. Note: The AI may have limitations with very complex images or fine details.
    `
  },
  {
    path: '/random-uuid-generator',
    menuTitle: 'UUID Gen',
    title: 'Random UUID Generator - Create Unique Identifiers',
    description: 'Generate random UUIDs (Universally Unique Identifiers) online for your applications and systems.',
    component: RandomUuidGenerator,
    type: 'utility',
    howToUse: `
      1. When you open the tool, you'll see an input field and a "Generate UUID" button.
      2. Click the "Generate UUID" button to create a new random UUID.
      3. The generated UUID will appear in the input field.
      4. You can click the "Copy" button next to the UUID to copy it to your clipboard.
      5. To generate multiple UUIDs at once:
         - Enter the desired number of UUIDs in the "Quantity" field.
         - Click "Generate Multiple UUIDs".
         - The tool will display a list of generated UUIDs.
      6. You can choose the UUID version (v1, v4, or v5) from the dropdown menu.
      7. For v5 UUIDs, you'll need to provide a namespace and name.
      8. The tool also offers an option to remove hyphens from the generated UUIDs.
      9. Use the "Clear" button to reset the input field and start over.
      10. The "History" feature allows you to view previously generated UUIDs during your session.
    `
  },
  {
    path: '/json-beautifier',
    title: 'JSON Beautifier - Format & Beautify JSON Online',
    description: 'Use our online JSON Beautifier for free to format and beautify your JSON code, enhancing readability and ease of debugging.',
    component: JsonBeautifier,
    menuTitle: 'Format JSON',
    type: 'JSON utility',
    howToUse: `
      1. Paste your JSON data into the input text area.
      2. Click the "Beautify" button to format your JSON with proper indentation and line breaks.
      3. The beautified JSON will appear in the output area.
      4. You can adjust the indentation width (2 spaces, 4 spaces, or tabs) using the dropdown.
      5. Use the "Minify" button to compress your JSON by removing all unnecessary whitespace.
      6. The "Copy" button allows you to copy the formatted or minified JSON to your clipboard.
      7. If there are any syntax errors in your JSON, the tool will highlight them and provide error messages.
      8. Use the "Clear" button to reset both input and output areas.
      9. The tool also offers a "Download" option to save your beautified JSON as a .json file.
      10. For large JSON files, you can use the "Load File" button to upload a JSON file directly.
    `
  },
  {
    path: '/json-stringifier',
    title: 'JSON Stringifier',
    description: 'Convert JSON objects into stringified JSON format.',
    component: JsonStringifier,
    type: 'JSON utility',
    menuTitle: 'JSON Stringifier',
    howToUse: `
      1. Enter or paste your JSON object into the input text area.
      2. Click the "Stringify" button to convert the JSON object to a JSON string.
      3. The stringified JSON will appear in the output area, enclosed in quotes and with escape characters.
      4. You can toggle the "Pretty Print" option to format the stringified output for better readability.
      5. Use the "Copy" button to copy the stringified JSON to your clipboard.
      6. The tool will validate your input JSON and show any errors if present.
      7. For nested objects or arrays, the tool will properly stringify all levels.
      8. You can use the "Clear" button to reset both input and output areas.
      9. The "Swap" button allows you to quickly move the output back to the input for further processing.
      10. For developers, there's an option to generate code snippets for stringifying in various programming languages.
    `
  },
  {
    path: '/json-unstringifier',
    title: 'JSON Unstringifier',
    description: 'Parse stringified JSON back into JSON objects.',
    component: JsonUnstringifier,
    type: 'JSON utility',
    menuTitle: 'JSON Unstringifier',
    howToUse: `
      1. Paste your stringified JSON (enclosed in quotes) into the input text area.
      2. Click the "Unstringify" button to parse the JSON string back into a JSON object.
      3. The unstringified (parsed) JSON will appear in the output area.
      4. You can toggle the "Pretty Print" option to format the output JSON for better readability.
      5. Use the "Copy" button to copy the unstringified JSON to your clipboard.
      6. The tool will validate the input and show any errors if the stringified JSON is invalid.
      7. For nested structures, the tool will properly parse all levels.
      8. Use the "Clear" button to reset both input and output areas.
      9. The "Swap" button allows you to quickly move the output back to the input for further processing.
      10. There's an option to view the parsed JSON as a collapsible tree structure for easier navigation of complex objects.
    `
  },
  {
    path: '/json-to-js-object',
    title: 'JSON to JavaScript Object',
    description: 'Convert JSON strings into JavaScript object format.',
    component: JsonToJsObject,
    type: 'JSON utility',
    menuTitle: 'JSON to JS Object',
    howToUse: `
      1. Enter or paste your JSON string into the input text area.
      2. Click the "Convert to JS Object" button to transform the JSON into a JavaScript object literal.
      3. The converted JavaScript object will appear in the output area.
      4. The tool will add variable declaration (const obj = ) to make it ready for use in JavaScript code.
      5. Use the "Copy" button to copy the JavaScript object to your clipboard.
      6. You can toggle between different output formats:
         - Object literal
         - ES6 class
         - TypeScript interface
      7. The tool will validate your input JSON and show any errors if present.
      8. For nested objects or arrays, the conversion will maintain the structure.
      9. Use the "Prettify" option to format the output for better readability.
      10. The "Download" button allows you to save the converted object as a .js file.
    `
  },
  {
    path: '/url-encoder-decoder',
    title: 'URL Encoder/Decoder - Encode or Decode URLs Instantly',
    description: 'Safely encode or decode URLs online with our user-friendly tool, ensuring data integrity and compatibility across the web.',
    component: UrlEncoderDecoder,
    type: 'encoder',
    menuTitle: 'URL Tools',
    howToUse: `
      1. Enter the URL or URL component you want to encode or decode in the input field.
      2. Click the "Encode" button to URL-encode your input.
         - This will replace special characters with their percent-encoded equivalents.
      3. Click the "Decode" button to URL-decode your input.
         - This will convert percent-encoded characters back to their original form.
      4. The result will appear in the output field below.
      5. Use the "Copy" button to copy the result to your clipboard.
      6. The tool provides options to:
         - Encode entire URLs
         - Encode only query parameters
         - Decode entire URLs or specific components
      7. For complex URLs, you can use the "Parse URL" feature to break down and encode/decode specific parts.
      8. The "Batch Mode" allows you to process multiple URLs at once.
      9. Use the "Clear" button to reset both input and output fields.
      10. The tool also offers a "URL Builder" to construct URLs with proper encoding.
    `
  },
  {
    path: '/base64-encoder-decoder',
    menuTitle: 'Base64 Convert',
    title: 'Base64 Encoder/Decoder - Convert Text to/from Base64',
    description: 'Efficiently encode or decode text to or from Base64 format, a vital tool for data handling and storage solutions.',
    component: Base64EncoderDecoder,
    type: 'encoder',
    howToUse: `
      1. Enter the text you want to encode or the Base64 string you want to decode in the input field.
      2. Click the "Encode to Base64" button to convert your text to Base64 format.
      3. Click the "Decode from Base64" button to convert a Base64 string back to plain text.
      4. The result will appear in the output field below.
      5. Use the "Copy" button to copy the result to your clipboard.
      6. The tool supports encoding/decoding of:
         - Plain text
         - URLs
         - Files (images, documents, etc.)
      7. For file encoding, use the "Upload File" button to select a file from your device.
      8. The "Auto-detect" feature attempts to determine if your input is Base64 or plain text.
      9. Use the "Clear" button to reset both input and output fields.
      10. The tool provides options for handling line breaks and padding in Base64 strings.
    `
  },
  {
    path: '/timezone-converter',
    menuTitle: 'Timezone Shift',
    title: 'Timezone Converter - Easily Convert Time Between Zones',
    description: 'Accurately convert time across different timezones with our simple-to-use Timezone Converter tool.',
    component: TimezoneConverter,
    type: 'utility',
    howToUse: `
      1. Select the source timezone from the dropdown menu or start typing to search.
      2. Enter the date and time you want to convert in the input fields.
      3. Select the target timezone from the second dropdown menu.
      4. The converted time will automatically appear in the result field.
      5. Use the "Swap" button to quickly switch between source and target timezones.
      6. The tool displays the time difference between the two selected timezones.
      7. You can toggle between 12-hour and 24-hour time formats.
      8. The "Current Time" button fills in the current date and time for quick conversions.
      9. Use the "Add to Comparison" feature to compare multiple timezones at once.
      10. The tool supports daylight saving time adjustments automatically.
      11. For frequent conversions, you can save favorite timezone pairs for quick access.
    `
  },
  {
    path: '/jwt-viewer-verifier',
    menuTitle: 'JWT Check',
    title: 'JWT Viewer/Verifier - Securely Inspect and Verify Tokens',
    description: 'View and verify JWT (JSON Web Tokens) seamlessly, ensuring the integrity and authenticity of tokenized data.',
    component: JwtViewerVerifier,
    type: 'JSON utility',
    howToUse: `
      1. Paste your JWT (JSON Web Token) into the input field.
      2. The tool will automatically decode and display the token's header and payload.
      3. The header and payload will be shown in a readable JSON format.
      4. To verify the token's signature:
         - Enter the secret key or public key (for RS256) in the provided field.
         - Click the "Verify Signature" button.
      5. The tool will indicate whether the signature is valid or invalid.
      6. Use the "Copy" buttons to copy individual parts (header, payload, signature) to your clipboard.
      7. The "Expiration Check" feature automatically checks if the token has expired based on the 'exp' claim.
      8. For tokens using the 'nbf' (Not Before) claim, the tool checks if the token is already valid.
      9. You can toggle between raw and decoded views of the token parts.
      10. The tool supports various JWT algorithms including HS256, RS256, and ES256.
    `
  } ,
  {
    path: '/date-calculator',
    menuTitle: 'Date Calculator',
    title: 'Date Calculator - Calculate Age and Date Differences',
    description: 'Instantly calculate age in various units or determine the exact difference between two dates with our Date Calculator.',
    component: DateCalculator,
    type: 'utility',
    howToUse: `
      1. To calculate age:
         - Enter a birthdate in the "Birth Date" field.
         - The tool will automatically calculate the age as of today's date.
         - You can adjust the "As of Date" to calculate age at a specific point in time.
      2. To calculate the difference between two dates:
         - Enter the start date in the "From Date" field.
         - Enter the end date in the "To Date" field.
         - The tool will calculate the difference in years, months, weeks, and days.
      3. Use the calendar picker or type dates in the format YYYY-MM-DD.
      4. The result will show:
         - Years, months, and days
         - Total months
         - Total weeks
         - Total days
      5. Toggle between different display formats (e.g., "2 years, 3 months, 5 days" or "27 months, 5 days").
      6. Use the "Swap Dates" button to quickly reverse the date order for difference calculations.
      7. The "Add/Subtract Time" feature allows you to add or subtract a specific duration from a date.
      8. You can exclude specific days (e.g., weekends) from calculations using the "Exclude Days" option.
      9. The tool supports both Gregorian and Julian calendar systems.
      10. Use the "Clear" button to reset all fields and start a new calculation.
    `
  },
  {
    path: '/json-diff-checker',
    menuTitle: 'JSON Compare',
    title: 'JSON Diff Checker - Find Differences in JSON Data',
    description: 'Compare two JSON objects and easily identify differences. Our tool highlights changes for quick analysis and debugging.',
    component: JsonDiffChecker,
    type: 'JSON utility',
    howToUse: `
      1. Paste your first JSON object into the "JSON 1" input area.
      2. Paste your second JSON object into the "JSON 2" input area.
      3. Click the "Compare JSON" button to start the comparison.
      4. The tool will display the differences between the two JSON objects:
         - Added properties (in green)
         - Removed properties (in red)
         - Modified values (in yellow)
      5. Use the "Expand All" and "Collapse All" buttons to navigate through nested structures.
      6. The "Line by Line" view shows both JSONs side by side with differences highlighted.
      7. The "Unified View" combines both JSONs into a single view, using color coding to show changes.
      8. You can toggle between showing all properties or only the differences.
      9. Use the "Copy Diff" button to copy the comparison result to your clipboard.
      10. The tool supports comparing large JSON objects with the "Load from File" option.
      11. Use the "Ignore Whitespace" option to focus on content changes rather than formatting.
    `
  },
  {
    path: '/csv-to-json',
    menuTitle: 'CSV to JSON Converter',
    title: 'CSV to JSON Converter - Easily Convert CSV Files to JSON Format',
    description: 'Effortlessly transform CSV files into JSON format, making it easier to handle and manipulate data.',
    component: csvtojson,
    type: 'JSON utility',
    howToUse: `
      1. Click the "Upload CSV" button to select a CSV file from your device, or paste CSV data into the input area.
      2. The tool will display a preview of your CSV data.
      3. Choose your CSV parsing options:
         - Delimiter (comma, tab, semicolon, etc.)
         - Whether to use the first row as headers
         - How to handle quotes and escapes
      4. Click the "Convert to JSON" button to process your CSV.
      5. The resulting JSON will appear in the output area.
      6. Use the "Pretty Print" option to format the JSON for better readability.
      7. The "Download JSON" button allows you to save the result as a .json file.
      8. For large files, use the "Stream Processing" option to handle CSV files that don't fit in memory.
      9. The "Custom Mapping" feature allows you to define how CSV columns map to JSON properties.
      10. Use the "Validate" button to check if your JSON output is valid.
      11. The tool provides options to handle special cases like multi-line fields and irregular csv structures.
    `
  },
  {
    path: '/json-to-csv',
    menuTitle: 'JSON to CSV Converter',
    title: 'JSON to CSV Converter - Convert JSON Data to CSV Format',
    description: 'Quickly convert JSON data into a CSV format, simplifying data analysis and reporting.',
    component: json2csv,
    type: 'JSON utility',
    howToUse: `
      1. Paste your JSON data into the input area or use the "Upload JSON" button to select a JSON file.
      2. The tool will display a preview of your JSON structure.
      3. Choose your CSV output options:
         - Delimiter (comma, tab, semicolon, etc.)
         - Whether to include a header row
         - Quote character for fields
      4. Click the "Convert to CSV" button to process your JSON.
      5. The resulting CSV will appear in the output area.
      6. Use the "Download CSV" button to save the result as a .csv file.
      7. For nested JSON structures, you can:
         - Flatten the structure (creating columns for nested properties)
         - Specify which nested fields to include in the CSV
      8. The "Custom Field Mapping" feature allows you to rename JSON keys in the resulting CSV.
      9. Use the "Preview" button to see how your CSV will look before finalizing the conversion.
      10. For large JSON files, the tool offers a "Streaming" option to handle data that doesn't fit in memory.
      11. The "Unwind Arrays" option allows you to create multiple rows for array items in your JSON.
    `
  },
  {
    path: '/css-minifier',
    menuTitle: 'CSS Minify',
    title: 'CSS Minifier - Optimize and Minify Your CSS Code',
    description: 'Speed up your website by minifying your CSS code. Our CSS Minifier reduces file size for faster load times.',
    component: CSSMinifier,
    type: 'utility',
    howToUse: `
      1. Paste your CSS code into the input text area.
      2. Click the "Minify CSS" button to process your code.
      3. The minified CSS will appear in the output area.
      4. Use the "Copy Minified CSS" button to copy the result to your clipboard.
      5. The "Download" button allows you to save the minified CSS as a .min.css file.
      6. Toggle additional options:
         - Remove comments
         - Preserve important comments
         - Compress color values
         - Combine media queries
      7. The tool displays the original and minified file sizes, showing the compression percentage.
      8. For large stylesheets, use the "Upload CSS File" option to process files directly.
      9. The "Beautify" option can format your CSS if you need to expand minified code.
      10. Use the "Compare" feature to see a side-by-side view of original and minified CSS.
    `
  },
  {
    path: '/color-tools',
    title: 'Color Tools',
    menuTitle: 'Color Tools',
    description: 'Interactive color picker and converter.',
    component: ColorTools,
    type: 'utility',
    howToUse: `
      1. Use the color picker to select a color visually.
      2. Input color values in various formats (HEX, RGB, HSL, etc.) in the text field.
      3. The tool will automatically convert and display the color in all supported formats.
      4. Adjust individual color components using sliders (Red, Green, Blue, Hue, Saturation, Lightness).
      5. Use the "Copy" buttons to copy color codes in different formats to your clipboard.
      6. The "Color Palette" feature generates complementary and analogous colors.
      7. Save favorite colors to your palette for future reference.
      8. Use the "Contrast Checker" to ensure color combinations meet accessibility standards.
      9. The "Color Mixer" allows you to blend two colors and see the result.
      10. Export your color selections as CSS, SCSS, or a color palette file.
    `
  },
  {
    path: '/regex-library',
    title: 'Regex Library',
    menuTitle: 'Regex Library',
    description: 'Collection of common regex patterns.',
    component: RegexLibrary,
    type: 'utility',
    howToUse: `
      1. Browse through categories of common regex patterns (e.g., Email, Phone Numbers, Dates).
      2. Click on a pattern to view its details, including the regex and a description.
      3. Use the search bar to find specific patterns by keyword or purpose.
      4. Copy the regex pattern to your clipboard using the "Copy" button.
      5. Test the regex pattern directly in the library using the built-in tester.
      6. View example matches and non-matches for each pattern.
      7. Customize patterns with modifiers (case-insensitive, global, etc.).
      8. Save frequently used patterns to your favorites for quick access.
      9. Contribute your own regex patterns to the community library.
      10. Use the "Export" feature to download selected patterns for offline use.
    `
  },
  {
    path: '/regex-tester',
    title: 'Regex Tester',
    menuTitle: 'Regex Tester',
    description: 'Test and debug regular expressions.',
    component: RegexTester,
    type: 'utility',
    howToUse: `
      1. Enter your regular expression in the "Regex" input field.
      2. Paste or type the test string in the "Test String" area.
      3. Select regex flags (global, case-insensitive, multiline) as needed.
      4. Click "Test Regex" to see matches highlighted in the test string.
      5. View a breakdown of capture groups and their contents.
      6. Use the "Explain" feature to get a plain English description of your regex.
      7. The "Replace" tab allows you to test regex replacement operations.
      8. Toggle between "Match" and "Split" modes to test different regex behaviors.
      9. Use the "Regex Builder" for step-by-step construction of complex patterns.
      10. Save and load regex patterns for future reference.
    `
  },
  {
    path: '/gradient-creator',
    title: 'Gradient Creator',
    menuTitle: 'Gradient Creator',
    description: 'Create and customize CSS gradients.',
    component: GradientCreator,
    type: 'utility',
    howToUse: `
      1. Choose the gradient type: Linear, Radial, or Conic.
      2. Use the color stops to define your gradient colors.
      3. Add or remove color stops using the "+" and "-" buttons.
      4. Adjust the position of color stops by dragging them or entering precise values.
      5. For linear gradients, set the angle or use preset directions.
      6. For radial gradients, adjust the shape and size.
      7. For conic gradients, set the center point and rotation angle.
      8. Preview your gradient in real-time.
      9. Copy the generated CSS code using the "Copy CSS" button.
      10. Use the "Export" feature to download your gradient as an image file.
    `
  },
  {
    path: '/gradient-samples',
    title: 'Gradient Samples',
    menuTitle: 'Gradient Samples',
    description: 'Explore a variety of CSS gradient samples.',
    component: GradientSamples,
    type: 'utility',
    howToUse: `
      1. Browse through the gallery of pre-made gradient samples.
      2. Filter gradients by type (Linear, Radial, Conic) or color scheme.
      3. Click on a gradient to view its details and CSS code.
      4. Use the "Copy CSS" button to copy the gradient code to your clipboard.
      5. Customize any gradient by clicking "Edit in Gradient Creator".
      6. Save your favorite gradients to a personal collection.
      7. Use the search function to find gradients by color or keyword.
      8. View gradients applied to sample UI elements for inspiration.
      9. Submit your own gradient creations to the community samples.
      10. Use the "Random" button to discover new gradient combinations.
    `
  },
  {
    path: '/color-picker',
    title: 'Color Picker',
    menuTitle: 'Color Picker',
    description: 'Pick colors from an uploaded image.',
    component: ColorPicker,
    type: 'utility',
    howToUse: `
      1. Click "Upload Image" or drag and drop an image into the designated area.
      2. Once the image is loaded, move your cursor over the image to select colors.
      3. Click on the image to capture a color.
      4. The selected color will appear in the color display box.
      5. View the color values in various formats (HEX, RGB, HSL).
      6. Use the "Copy" buttons to copy color codes to your clipboard.
      7. The "History" panel shows your recently picked colors.
      8. Click on colors in the history to re-select them.
      9. Use the zoom feature for precise color selection in detailed areas.
      10. The "Extract Palette" feature automatically generates a color palette from the image.
    `
  },
  {
    path: '/svg-to-png',
    component: SvgToPng,
    title: 'Convert SVG to PNG',
    description: 'Convert SVG code to PNG image format',
    menuTitle: 'SVG to PNG',
    type: 'Image utility',
    howToUse: `
      1. Paste your SVG code into the input text area or use the "Upload SVG" button.
      2. Set the desired dimensions for the output PNG image.
      3. Choose the background color (transparent or custom color).
      4. Click the "Convert to PNG" button to process your SVG.
      5. Preview the generated PNG image.
      6. Use the "Download PNG" button to save the converted image.
      7. Adjust SVG rendering options if needed (e.g., preserve aspect ratio).
      8. For batch conversion, use the "Bulk Convert" feature to process multiple SVGs.
      9. The "Optimize SVG" option can reduce file size before conversion.
      10. Use the "Custom CSS" field to apply styles to your SVG before conversion.
    `
  }
];

export default routes;
