import React, { useState } from 'react';
import './UnixTimeConverter.css';

const UnixTimeConverter = () => {
  const [unixTime, setUnixTime] = useState('');
  const [humanReadableDate, setHumanReadableDate] = useState('');

  const convertUnixToHuman = () => {
    const date = new Date(parseInt(unixTime) * 1000);
    setHumanReadableDate(date.toISOString());
  };

  const convertHumanToUnix = () => {
    const date = new Date(humanReadableDate);
    setUnixTime(Math.floor(date.getTime() / 1000).toString());
  };

  return (
    <div className="unix-time-converter">
      <div>
        <input
          type="number"
          value={unixTime}
          onChange={(e) => setUnixTime(e.target.value)}
          placeholder="Unix timestamp"
        />
        <button onClick={convertUnixToHuman}>Convert to Date</button>
      </div>
      <div>
        <input
          type="datetime-local"
          value={humanReadableDate}
          onChange={(e) => setHumanReadableDate(e.target.value)}
        />
        <button onClick={convertHumanToUnix}>Convert to Unix Time</button>
      </div>
    </div>
  );
};

export default UnixTimeConverter;