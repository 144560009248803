import React, { useState } from 'react';
import Papa from 'papaparse';
import '../json-utility.css'
/**
 * Description
 * @return {any}
 */
function CSVToJsonConverter() {
  const [jsonData, setJsonData] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          setJsonData(JSON.stringify(result.data, null, 2));
        },
        header: true,
      });
    }
  };

  const downloadJsonFile = () => {
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'converted.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="json-utility-container">
      <input type="file" onChange={handleFileChange} accept=".csv" />
      {jsonData && (
        <>
          <button onClick={downloadJsonFile}>Download JSON</button>
          <pre>{jsonData}</pre>
        </>
      )}
    </div>
  );
}

export default CSVToJsonConverter;
