import React, { useState } from 'react';
import axios from 'axios';
import './BulkCompressConvertImage.css'; // Import your local CSS file
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import Image from '../../../components/Image/Image';
import serviceConstants from '../../../constants/serviceConstants';
import '../image.css';
const BulkCompressConvertImage = () => {
  const [imageFiles, setImageFiles] = useState([]);
  const [outputFormat, setOutputFormat] = useState('jpeg');
  const [quality, setQuality] = useState(80);
  const [convertedImages, setConvertedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const formatOptions = serviceConstants.ImageFormatOptions;
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

  const handleImageChange = (e) => {
    setImageFiles([...e.target.files]);
  };

  const handleFormatChange = (e) => {
    setOutputFormat(e.target.value);
  };

  const handleQualityChange = (e) => {
    setQuality(e.target.value);
  };

  const handleCompressConvertImages = async () => {
    setIsLoading(true);
    const formData = new FormData();
    imageFiles.forEach((file) => {
      formData.append('imageFiles', file);
    });
    formData.append('format', outputFormat);
    formData.append('quality', quality);

    try {
      const response = await axios.post(
        `${baseUrl}/image/bulk-compress-convert`,
        formData,
        { responseType: 'blob' }
      );
      setConvertedImages([
        ...convertedImages,
        URL.createObjectURL(response.data),
      ]);
    } catch (error) {
      console.error('Error compressing and converting images:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="image-utility-container">
      <h2>Bulk Compress and Convert Image Format</h2>
      <Input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageChange}
      />
      <Select
        options={formatOptions}
        value={outputFormat}
        onChange={handleFormatChange}
        placeholder={'Image Format'}
      />
      <label htmlFor="quality">Quality (1-100):</label>
      <Input
        type="range"
        id="quality"
        name="quality"
        min="1"
        max="100"
        value={quality}
        onChange={handleQualityChange}
      />
      <Button
        onClick={handleCompressConvertImages}
        disabled={imageFiles.length === 0 || isLoading}
      >
        Compress and Convert
      </Button>
      {convertedImages.length > 0 && (
        <div>
          <h3>Converted Images:</h3>
          {convertedImages.map((image, index) => (
            <Image key={index} src={image} alt={`Converted ${index}`} />
          ))}
        </div>
      )}
    </div>
  );
};

export default BulkCompressConvertImage;
