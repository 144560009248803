import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import './GradientCreator.css'
import '../utility.css'

const GradientCreator = () => {
    const [startColor, setStartColor] = useState('#ff0000');
    const [endColor, setEndColor] = useState('#0000ff');
    const [direction, setDirection] = useState('to right');
    const [rotation, setRotation] = useState(0); // For rotation degree
    const [percentage, setPercentage] = useState(50);
    const [useDegree, setUseDegree] = useState(false);
    const handleStartColorChange = (color) => {
        setStartColor(color.hex);
    };

    const handleEndColorChange = (color) => {
        setEndColor(color.hex);
    };

    const handleDirectionChange = (e) => {
        setDirection(e.target.value);
    };

    // const gradientCSS = `background: linear-gradient(${direction}, ${startColor}, ${endColor});`;
    // const gradientCSS = `background: linear-gradient(${rotation}deg, ${startColor} ${percentage}%, ${endColor})`;
    const gradientCSS = useDegree
    ? `linear-gradient(${rotation}deg, ${startColor} ${percentage}%, ${endColor})`
    : `linear-gradient(${direction}, ${startColor} ${percentage}%, ${endColor})`;
  

    return (
        <div className="utility-container">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <div>
                    <h3>Start Color</h3>
                    <SketchPicker color={startColor} onChangeComplete={handleStartColorChange} />
                </div>
                <div>
                    <h3>End Color</h3>
                    <SketchPicker color={endColor} onChangeComplete={handleEndColorChange} />
                </div>
                <div>
                    <div>
                        <h3>Direction</h3>
                        <select value={direction} onChange={handleDirectionChange}>
                            <option value="to right">To Right</option>
                            <option value="to left">To Left</option>
                            <option value="to bottom">To Bottom</option>
                            <option value="to top">To Top</option>
                            <option value="to bottom right">To Bottom Right</option>
                            <option value="to bottom left">To Bottom Left</option>
                            <option value="to top right">To Top Right</option>
                            <option value="to top left">To Top Left</option>
                            <option value="circle">Circular</option>
                        </select>
                    </div>
                    <div>
                    <label>
  <input type="checkbox" checked={useDegree} onChange={() => setUseDegree(!useDegree)} />
  Toggle Between Direction and Degree
</label>
                    </div>
                    <div>
                        <p>Rotation in degrees</p>
                        <input type="range" min="0" max="360" value={rotation} onChange={e => setRotation(e.target.value)} />
                    </div>

                    <div>
                        <p>Percentage</p>
                        <input type="range" min="0" max="100" value={percentage} onChange={e => setPercentage(e.target.value)} />
                    </div>
                </div>
            </div>
            <div style={{ background: `${gradientCSS}`, height: '200px', width: '100%', marginTop: '20px' }}>
                {/* <p>Gradient Preview</p> */}
            </div>

            <div>
                <h3>CSS Code</h3>
                <textarea readOnly value={`${gradientCSS}`} />
                <button onClick={() => navigator.clipboard.writeText(gradientCSS)}>Copy CSS</button>
            </div>
        </div>
    );
};

export default GradientCreator;
