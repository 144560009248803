import React, { useState } from 'react';
import YAML from 'js-yaml';
import './JsonYamlConverter.css';

const JsonYamlConverter = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [isJson, setIsJson] = useState(true);

  const convert = () => {
    try {
      if (isJson) {
        const jsonObj = JSON.parse(input);
        setOutput(YAML.dump(jsonObj));
      } else {
        const yamlObj = YAML.load(input);
        setOutput(JSON.stringify(yamlObj, null, 2));
      }
    } catch (error) {
      setOutput('Error: Invalid input');
    }
  };

  return (
    <div className="json-yaml-converter">
      <div className="input-container">
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={isJson ? "Enter JSON here..." : "Enter YAML here..."}
        />
      </div>
      <div className="controls">
        <button onClick={() => setIsJson(!isJson)}>
          Switch to {isJson ? "YAML to JSON" : "JSON to YAML"}
        </button>
        <button onClick={convert}>Convert</button>
      </div>
      <div className="output-container">
        <textarea value={output} readOnly placeholder="Conversion result..." />
      </div>
    </div>
  );
};

export default JsonYamlConverter;