import React, { useState } from 'react';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import './AddWatermarkToPDF.css';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import '../pdf.css';
const AddWatermarkToPDF = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [watermarkedPdfBlob, setWatermarkedPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [watermarkText, setWatermarkText] = useState('');
  const [options, setOptions] = useState({
    fontSize: 25,
    xOffset: 50,
    yOffset: 30,
  });

  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handleWatermarkTextChange = (e) => {
    setWatermarkText(e.target.value);
  };

  const handleOptionsChange = (e) => {
    const { name, value } = e.target;
    setOptions((prevOptions) => ({ ...prevOptions, [name]: value }));
  };

  const handleAddWatermark = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('pdfFile', pdfFile);
    formData.append('watermarkText', watermarkText);
    formData.append('options', JSON.stringify(options));

    try {
      const response = await axios.post(
        `${baseUrl}/pdf/add-watermark`,
        formData,
        { responseType: 'blob' }
      );
      setWatermarkedPdfBlob(
        new Blob([response.data], { type: 'application/pdf' })
      );
    } catch (error) {
      console.error('Error adding watermark:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadWatermarkedPdf = () => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(watermarkedPdfBlob);
    link.setAttribute('download', 'watermarked_pdf.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="pdf-utility-container">
      <h2>Add Watermark to PDF</h2>
      <Input type="file" accept="application/pdf" onChange={handleFileChange} />
      <Input
        type="text"
        value={watermarkText}
        onChange={handleWatermarkTextChange}
        placeholder="Enter Watermark Text"
      />
      <Input
        type="number"
        name="fontSize"
        value={options.fontSize}
        onChange={handleOptionsChange}
        placeholder="Font Size"
      />
      <Input
        type="number"
        name="xOffset"
        value={options.xOffset}
        onChange={handleOptionsChange}
        placeholder="X Offset"
      />
      <Input
        type="number"
        name="yOffset"
        value={options.yOffset}
        onChange={handleOptionsChange}
        placeholder="Y Offset"
      />
      <Button onClick={handleAddWatermark} disabled={!pdfFile || isLoading}>
        Add Watermark
      </Button>
      {watermarkedPdfBlob && (
        <div>
          <Document file={watermarkedPdfBlob}>
            <Page pageNumber={1} width={600} />
          </Document>
          <Button onClick={downloadWatermarkedPdf}>
            Download Watermarked PDF
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddWatermarkToPDF;
