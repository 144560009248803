import React, { useState } from 'react';
import { makeApiCall } from '../../utils/api';
import PropTypes from 'prop-types';

const GenerateCSV = ({ apiEndpoint, title, description, howToUse }) => {
  const [template, setTemplate] = useState('');
  const [numRows, setNumRows] = useState(10);
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      console.log(apiEndpoint);
      const data = await makeApiCall(apiEndpoint, { template: template });
      setResult(data);
    } catch (error) {
      console.log(error);
      setResult({ error: 'Failed to generate CSV. Please check your template and try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadCSV = () => {
    if (result && result.csvData) {
      const blob = new Blob([result.csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'generated_data.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <div className="generate-csv-container">
      <h1>{title}</h1>
      <p>{description}</p>
     
      <form onSubmit={handleSubmit}>
        <textarea
          value={template}
          onChange={(e) => setTemplate(e.target.value)}
          placeholder={`Generate a dialogue between a support agent and a customer discussing a support request related to a Shoe shop called "Comfort Run." The conversation should be structured with columns labeled: 'Customer Response 1', 'Agent Response 1', 'Customer Response 2', 'Agent Response 2', and so on, up to 'Customer Response 5' and 'Agent Response 5'. Additionally, include columns labeled 'Tags' to categorize the conversation and 'Sentiment' to indicate the emotional tone of the interaction.`}
          rows="6"
        />
        <label>
          Number of rows:
          <input
            type="number"
            value={numRows}
            onChange={(e) => setNumRows(parseInt(e.target.value))}
            min="1"
            max="1000"
          />
        </label>
        <button 
          type="submit" 
          disabled={isLoading || template.trim() === ''}
        >
          {isLoading ? 'Generating...' : 'Generate CSV'}
        </button>
      </form>
      {result && !result.error && (
        <div className="result">
          <h2>Generated CSV Preview:</h2>
          <pre>{result.csvData.slice(0, 500)}...</pre>
          <button onClick={downloadCSV}>Download CSV</button>
        </div>
      )}
      {result && result.error && (
        <div className="error">
          <p>{result.error}</p>
        </div>
      )}
    </div>
  );
};

GenerateCSV.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  howToUse: PropTypes.string.isRequired,
};

export default GenerateCSV;