import React, { useState } from 'react';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import './HtmlToPdf.css';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Textarea from '../../../components/Textarea/Textarea';
import '../pdf.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const HtmlToPdf = () => {
  const [htmlContent, setHtmlContent] = useState('');
  const [options, setOptions] = useState({
    fontSize: 25,
    xOffset: 50,
    yOffset: 30,
  });
  const [generatedPdfBlob, setGeneratedPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

  const handleHtmlChange = (e) => {
    setHtmlContent(e.target.value);
  };

  const handleOptionsChange = (e) => {
    const { name, value } = e.target;
    setOptions((prevOptions) => ({ ...prevOptions, [name]: value }));
  };

  const handleConvertToPdf = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/pdf/html-to-pdf`,
        {
          html: htmlContent,
          options,
        },
        { responseType: 'blob' }
      );
      setGeneratedPdfBlob(
        new Blob([response.data], { type: 'application/pdf' })
      );
    } catch (error) {
      console.error('Error converting HTML to PDF:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadGeneratedPdf = () => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(generatedPdfBlob);
    link.setAttribute('download', 'generated_pdf.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="pdf-utility-container">
      <h2>Convert HTML to PDF</h2>
      <Textarea
        value={htmlContent}
        onChange={handleHtmlChange}
        placeholder="Enter HTML here"
      />
      <Input
        type="number"
        name="fontSize"
        value={options.fontSize}
        onChange={handleOptionsChange}
        placeholder="Font Size"
      />
      <Input
        type="number"
        name="xOffset"
        value={options.xOffset}
        onChange={handleOptionsChange}
        placeholder="X Offset"
      />
      <Input
        type="number"
        name="yOffset"
        value={options.yOffset}
        onChange={handleOptionsChange}
        placeholder="Y Offset"
      />
      <Button onClick={handleConvertToPdf} disabled={isLoading}>
        Convert to PDF
      </Button>
      {generatedPdfBlob && (
        <div>
          <Document file={generatedPdfBlob}>
            <Page pageNumber={1} width={600} />
          </Document>
          <Button onClick={downloadGeneratedPdf}>Download PDF</Button>
        </div>
      )}
    </div>
  );
};

export default HtmlToPdf;
