import React from 'react';
import './Textarea.css'; // Assuming you have a separate CSS file for styling
import PropTypes from 'prop-types';

const Textarea = ({ value, onChange, rows = 4, cols = 50, ...props }) => {
  return (
    <div className="textarea-container">
      <textarea
        className="textarea-element"
        rows={rows}
        cols={cols}
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};
Textarea.propTypes = {
  value: PropTypes.string.isRequired, // Require a string value
  onChange: PropTypes.func.isRequired, // Require a function for onChange
  rows: PropTypes.number, // Optional number of rows
  cols: PropTypes.number, // Optional number of columns
};

export default Textarea;
