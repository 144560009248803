import React, { useState } from 'react';
import { html as beautifyHtml } from 'js-beautify';
import Textarea from '../../../components/Textarea/Textarea';
import Button from '../../../components/Button/Button';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HtmlBeautifyMinify = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const beautify = () => {
    try {
      const beautifiedHtml = beautifyHtml(input, {
        indent_size: 2,
        wrap_line_length: 80,
        preserve_newlines: true,
        max_preserve_newlines: 2,
      });
      setOutput(beautifiedHtml);
      toast.success('HTML beautified successfully!');
    } catch (error) {
      toast.error('Error beautifying HTML. Please check your input.');
    }
  };

  const minify = () => {
    try {
      const minifiedHtml = input
        .replace(/\s+/g, ' ')
        .replace(/>\s+</g, '><')
        .trim();
      setOutput(minifiedHtml);
      toast.success('HTML minified successfully!');
    } catch (error) {
      toast.error('Error minifying HTML. Please check your input.');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(output).then(
      () => toast.success('Copied to clipboard!'),
      () => toast.error('Failed to copy to clipboard')
    );
  };

  return (
    <div className="html-beautify-minify">
      <h2>HTML Beautify/Minify</h2>
      <Textarea
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Enter HTML here"
        rows={10}
      />
      <div className="button-group">
        <Button onClick={beautify}>Beautify</Button>
        <Button onClick={minify}>Minify</Button>
      </div>
      <Textarea 
        value={output} 
        readOnly 
        placeholder="Output will appear here" 
        rows={10}
      />
      <Button onClick={copyToClipboard}>Copy to Clipboard</Button>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default HtmlBeautifyMinify;