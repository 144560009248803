import './RandomUuidGenerator.css';
import Button from '../../../components/Button/Button';
import Textarea from '../../../components/Textarea/Textarea';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import '../utility.css'

const RandomUuidGenerator = () => {
  const [uuid, setUuid] = useState('');

  const generateUuid = () => {
    const newUuid = uuidv4();
    setUuid(newUuid);
  };

  return (
    <div className="utility-container bodybeautifier">
      <Button onClick={generateUuid}>Generate UUID</Button>
      <Textarea className="Textarea" value={uuid} readOnly></Textarea>
    </div>
  );
};

export default RandomUuidGenerator;
