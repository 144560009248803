import React from 'react';

const regexLibrary = [
  { name: 'Email', regex: '/\\S+@\\S+\\.\\S+/' },
  {
    name: 'URL',
    regex:
      '/https?:\\/\\/(www\\.)?[a-z0-9\\-\\.]+\\.[a-z]{2,5}([\\/\\w \\.-]*)*/',
  },
  { name: 'IP Address', regex: '/(\\d{1,3}\\.){3}\\d{1,3}/' },
  { name: 'Hex Color', regex: '/^#?([a-f0-9]{6}|[a-f0-9]{3})$/' },
  { name: 'HTML Tag', regex: '/<([a-z]+) *[^\\/]*?>/' },
  { name: 'Username', regex: '/^[a-z0-9_-]{3,16}$/' },
  { name: 'Password', regex: '/^[a-z0-9_-]{6,18}$/' },
  {
    name: 'Date (YYYY-MM-DD)',
    regex:
      '/(19|20)\\d\\d[- \\/.](0[1-9]|1[012])[- \\/.](0[1-9]|[12][0-9]|3[01])/',
  },
  { name: 'Time (HH:MM 24-hour)', regex: '/^([01]?\\d|2[0-3]):([0-5]\\d)$/' },
  {
    name: 'Credit Card Number',
    regex:
      '/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9]{2})[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\\d{3})\\d{11})$/',
  },
  {
    name: 'Social Security Number (SSN)',
    regex: '/^(?!000|666|9\\d\\d)\\d{3}-(?!00)\\d{2}-(?!0000)\\d{4}$/',
  },
  { name: 'Postal Code (US ZIP Code)', regex: '/^\\d{5}(-\\d{4})?$/' },
  { name: 'Image File Extension', regex: '/([^s]+(.(?i)(jpg|png|gif|bmp))$)/' },
  {
    name: 'Audio File Extension',
    regex: '/([^s]+(.(?i)(mp3|wav|flac|aac|ogg))$)/',
  },
  {
    name: 'Video File Extension',
    regex: '/([^s]+(.(?i)(mp4|avi|mkv|wmv|mov))$)/',
  },
  {
    name: 'Document File Extension',
    regex: '/([^s]+(.(?i)(pdf|docx|doc|txt|ppt|pptx))$)/',
  },
  { name: 'Alphanumeric', regex: '/^[a-z0-9]+$/i' },
  { name: 'Numeric', regex: '/^\\d+$/' },
  { name: 'Positive Integer', regex: '/^\\d*[1-9]\\d*$/' },
  { name: 'Negative Integer', regex: '/^-\\d*[1-9]\\d*$/' },
  { name: 'Positive or Negative Integer', regex: '/^-?\\d*[1-9]\\d*$/' },
  { name: 'Positive Number', regex: '/^\\d*\\.?\\d+$/' },
  { name: 'Negative Number', regex: '/^-\\d*\\.?\\d+$/' },
  { name: 'Positive or Negative Number', regex: '/^-?\\d*\\.?\\d+$/' },
  { name: 'ASCII Characters', regex: '/^[\\x00-\\x7F]+$/' },
  { name: 'Unicode Characters', regex: '/^[^\\x00-\\x7F]+$/' },
  {
    name: 'Base64',
    regex:
      '/^(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+\\/]{3}=)?$/',
  },
  { name: 'Lowercase Letters', regex: '/^[a-z]+$/' },
  { name: 'Uppercase Letters', regex: '/^[A-Z]+$/' },
  { name: 'Case Insensitive Letters', regex: '/^[a-zA-Z]+$/' },
  { name: 'Basic Latin Letters', regex: '/^[a-zA-Z]+$/' },
  { name: 'No Whitespace', regex: '/^\\S+$/' },
  { name: 'Whitespace', regex: '/\\s+/' },
  { name: 'Leading and Trailing Whitespace', regex: '/^\\s+|\\s+$/' },
  { name: 'CSV Values', regex: '/^([^,]+,)+[^,]+$/' },
  { name: 'MD5 Hash', regex: '/^[a-f0-9]{32}$/' },
  { name: 'SHA1 Hash', regex: '/^[a-f0-9]{40}$/' },
  { name: 'SHA256 Hash', regex: '/^[a-f0-9]{64}$/' },
  {
    name: 'IPv4 Address',
    regex:
      '/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/',
  },
  { name: 'IPv6 Address', regex: '/^([0-9a-f]{1,4}:){7}[0-9a-f]{1,4}$/' },
  { name: 'MAC Address', regex: '/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/' },
  {
    name: 'ISBN 10 or 13',
    regex:
      '/^(?:ISBN(?:-1[03])?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/',
  },
  {
    name: 'JSON String',
    regex: '/^\\{(?:[^{}]|\\{(?:[^{}]|\\{[^{}]*\\})*\\})*\\}$/',
  },
  { name: 'Multiline Text', regex: '/[\\s\\S]*/' },
  { name: 'Non-Digit Characters', regex: '/\\D/' },
  { name: 'Non-Alphanumeric Characters', regex: '/\\W/' },
  { name: 'Non-ASCII Characters', regex: '/[\\x80-\\xFF]/' },
  { name: 'Slug (URL friendly string)', regex: '/^[a-z0-9]+(?:-[a-z0-9]+)*$/' },
  { name: 'Twitter Handle', regex: '/^@?(\\w){1,15}$/' },
  {
    name: 'UUID',
    regex: '/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/',
  },
  { name: 'VAT Number', regex: '/^[A-Z]{2}[0-9]{8}$/' },
  { name: 'YouTube Video ID', regex: '/^[a-zA-Z0-9_-]{11}$/' },
  { name: 'Hashtag', regex: '/^#[a-zA-Z0-9_]+$/' },
];

const RegexLibrary = () => {
  return (
    <div>
      <h2>Regex Library</h2>
      <ul>
        {regexLibrary.map((item, index) => (
          <li key={index}>
            <b>{item.name}:</b> {item.regex}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RegexLibrary;
