import React, { useState } from 'react';
import axios from 'axios';
import Select from '../../../components/Select/Select';
import Image from '../../../components/Image/Image';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import serviceConstants from '../../../constants/serviceConstants';
import './ChangeImageFormat.css'
import '../image.css';
const ChangeImageFormat = () => {
  const [imageFile, setImageFile] = useState(null);
  const [convertedImage, setConvertedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [format, setFormat] = useState('jpeg');
  const formatOptions = serviceConstants.ImageFormatOptions;
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleFormatChange = (e) => {
    setFormat(e.target.value);
  };

  const handleChangeFormat = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('imageFile', imageFile);
    formData.append('format', format);

    try {
      const response = await axios.post(
        `${baseUrl}/image/change-format`,
        formData,
        { responseType: 'blob' }
      );
      setConvertedImage(URL.createObjectURL(response.data));
    } catch (error) {
      console.error('Error changing image format:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadConvertedImage = () => {
    const link = document.createElement('a');
    link.href = convertedImage;
    link.setAttribute('download', `converted_image.${format}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="image-utility-container">
      <h2>Change Image Format</h2>
      <Input type="file" accept="image/*" onChange={handleImageChange} />
      <Select
        options={formatOptions}
        value={format}
        onChange={handleFormatChange}
        placeholder={'Image Format'}
      />

      <Button onClick={handleChangeFormat} disabled={!imageFile || isLoading}>
        Change Format
      </Button>
      {convertedImage && (
        <div>
          <Image src={convertedImage} alt="Converted" />
          <Button onClick={downloadConvertedImage}>
            Download Converted Image
          </Button>
        </div>
      )}
    </div>
  );
};

export default ChangeImageFormat;
