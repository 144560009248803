import './JwtViewerVerifier.css';

import React, { useState } from 'react';
import jwt from 'jsonwebtoken'; // Ensure jsonwebtoken package is installed

import Select from '../../../components/Select/Select';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import serviceConstants from '../../../constants/serviceConstants';
import Textarea from '../../../components/Textarea/Textarea';

const JwtViewerVerifier = () => {
  const [jwtToken, setJwtToken] = useState('');
  const [decoded, setDecoded] = useState('');
  const [secret, setSecret] = useState('');
  const [algorithm, setAlgorithm] = useState('HS256');
  const formatOptions = serviceConstants.Algorithms;
  const handleJwtChange = (e) => setJwtToken(e.target.value);
  const handleSecretChange = (e) => setSecret(e.target.value);

  const decodeToken = () => {
    try {
      const decodedToken = jwt.decode(jwtToken);
      setDecoded(JSON.stringify(decodedToken, null, 2));
    } catch (error) {
      setDecoded('Invalid JWT Token');
    }
  };

  const verifyToken = () => {
    try {
      jwt.verify(jwtToken, secret, { algorithms: [algorithm] });
      setDecoded('Token is valid');
    } catch (error) {
      setDecoded('Token is invalid');
    }
  };

  return (
    <div className="bodybeautifier">
      <div className="column">
        <Textarea
          className="iptextarea"
          value={jwtToken}
          onChange={handleJwtChange}
          placeholder="Enter JWT here..."
        ></Textarea>
        <Input
          className="isp"
          type="text"
          value={secret}
          onChange={handleSecretChange}
          placeholder="Enter secret key for verification"
        />
      </div>
      <div className="column">
        <Select
          options={formatOptions}
          value={algorithm}
          onChange={(e) => setAlgorithm(e.target.value)}
          placeholder={'Algorithm'}
        />
        {/* <select value={algorithm} onChange={(e) => setAlgorithm(e.target.value)}>
  <option value="HS256">HS256</option>
  <option value="HS384">HS384</option>
  <option value="HS512">HS512</option>
  <option value="RS256">RS256</option>
  <option value="RS384">RS384</option>
  <option value="RS512">RS512</option>
  <option value="ES256">ES256</option>
  <option value="ES384">ES384</option>
  <option value="ES512">ES512</option>
  <option value="PS256">PS256</option>
  <option value="PS384">PS384</option>
  <option value="PS512">PS512</option>
  <option value="none">none</option>
</select> */}
        <Button onClick={decodeToken}>Decode JWT</Button>
        <Button onClick={verifyToken}>Verify JWT</Button>
      </div>
      <Textarea className="Textarea" value={decoded} readOnly></Textarea>
    </div>
  );
};

export default JwtViewerVerifier;
