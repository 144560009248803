import React, { useState } from 'react';
import './UrlEncoderDecoder.css';
import Textarea from '../../../components/Textarea/Textarea';
import Button from '../../../components/Button/Button';
import PropTypes from 'prop-types';
const UrlEncoderDecoder = ({ encodeLabel, decodeLabel }) => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleEncode = () => {
    setOutput(encodeURIComponent(input));
  };

  const handleDecode = () => {
    setOutput(decodeURIComponent(input));
  };

  return (
    <div className="bodybeautifier">
      <Textarea
        className="Textarea"
        value={input}
        onChange={handleInputChange}
        placeholder="Enter text here..."
      ></Textarea>
      <Button onClick={handleEncode}>{encodeLabel || 'Encode'}</Button>
      <Button onClick={handleDecode}>{decodeLabel || 'Decode'}</Button>
      <Textarea className="Textarea" value={output} readOnly></Textarea>
    </div>
  );
};
UrlEncoderDecoder.propTypes = {
  encodeLabel: PropTypes.string,
  decodeLabel: PropTypes.string,
};

export default UrlEncoderDecoder;
