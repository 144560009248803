import React, { useState } from 'react';
import './Base64EncoderDecoder.css';
import Textarea from '../../../components/Textarea/Textarea';
import Button from '../../../components/Button/Button';
const Base64EncoderDecoder = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');

  const handleInputChange = (e) => setInput(e.target.value);

  const handleEncode = () => setOutput(btoa(input));

  const handleDecode = () => {
    try {
      setOutput(atob(input));
    } catch (e) {
      setOutput('Invalid Base64 string');
    }
  };

  return (
    <div className="bodybeautifier">
      <Textarea
        className="textarea"
        value={input}
        onChange={handleInputChange}
        placeholder="Enter text here..."
      ></Textarea>
      <Button onClick={handleEncode}>Encode</Button>
      <Button onClick={handleDecode}>Decode</Button>
      <Textarea className="textarea" value={output} readOnly></Textarea>
    </div>
  );
};

export default Base64EncoderDecoder;
