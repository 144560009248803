import React, { useState } from 'react';
import axios from 'axios';
import './ResizeImageCanvas.css';
import Image from '../../../components/Image/Image';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import '../image.css';
const ResizeImageCanvas = () => {
  const [imageFile, setImageFile] = useState(null);
  const [resizedImage, setResizedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');

  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleWidthChange = (e) => {
    setWidth(e.target.value);
  };

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const handleResizeCanvas = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('imageFile', imageFile);
    formData.append('width', width);
    formData.append('height', height);

    try {
      const response = await axios.post(
        `${baseUrl}/image/resizecanvas`,
        formData,
        { responseType: 'blob' }
      );
      setResizedImage(URL.createObjectURL(response.data));
    } catch (error) {
      console.error('Error resizing image canvas:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadResizedImage = () => {
    const link = document.createElement('a');
    link.href = resizedImage;
    link.setAttribute('download', `resized_image.png`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="image-utility-container">
      <h2>Resize Image Canvas</h2>
      <Input type="file" accept="image/*" onChange={handleImageChange} />
      <Input
        type="number"
        value={width}
        onChange={handleWidthChange}
        placeholder="New Width"
      />
      <Input
        type="number"
        value={height}
        onChange={handleHeightChange}
        placeholder="New Height"
      />
      <Button onClick={handleResizeCanvas} disabled={!imageFile || isLoading}>
        Resize Canvas
      </Button>
      {resizedImage && (
        <div>
          <Image src={resizedImage} alt="Resized" />
          <Button onClick={downloadResizedImage}>Download Resized Image</Button>
        </div>
      )}
    </div>
  );
};

export default ResizeImageCanvas;