import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>404: Page Not Found</h1>
      {/* eslint-disable-next-line*/}
      <p>Sorry, the page you're looking for cannot be found.</p>
      {/* Add links to other pages or a search bar here */}
    </div>
  );
};

export default NotFound;
