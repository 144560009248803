import React from 'react';
import './Image.css'; // Assuming you have a separate CSS file for styling
import PropTypes from 'prop-types';
const Image = ({ src, alt, ...props }) => {
  return <img src={src} alt={alt} {...props} />;
};
Image.propTypes = {
  src: PropTypes.string.isRequired, // Require a string value for 'src'
  alt: PropTypes.string.isRequired, // Require a string value for 'alt'
};
export default Image;
