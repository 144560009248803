import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import convert from 'xml-js';
import './JsonBeautifier.css';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Textarea from '../../../components/Textarea/Textarea';
import '../json-utility.css'
/**
 * Description
 * @author Raghav Aadithya
 * @date 2024-01-14
 * @return {any}
 */
function JsonBeautifier() {
  const [inputJson, setInputJson] = useState('');
  const [formattedJson, setFormattedJson] = useState('');
  const [jsonPath, setJsonPath] = useState('');
  const [xml, setXml] = useState('');
  const [isJsonToXml, setIsJsonToXml] = useState(false);

  const handleJsonChange = (e) => {
    setInputJson(e.target.value);
    try {
      let json = JSON.parse(e.target.value);
      if (typeof json === 'string') {
        json = JSON.parse(json);
      }
      setFormattedJson(JSON.stringify(json, null, 4));
      setInputJson(JSON.stringify(json, null, 4));
    } catch (error) {
      try {
        const jsonObj = eval('(' + e.target.value + ')');
        setFormattedJson(JSON.stringify(jsonObj, null, 4));
        setInputJson(JSON.stringify(jsonObj, null, 4));
      } catch (evalError) {
        toast.error('Error parsing JSON or JavaScript object.');
        setFormattedJson('');
        setInputJson('');
      }
    }
  };

  const handleCopyFormattedJson = () => {
    if (formattedJson) {
      navigator.clipboard
        .writeText(formattedJson)
        .then(() => toast.success('Formatted JSON copied to clipboard!'))
        .catch(() => toast.error('Failed to copy!'));
    }
  };

  const handleSelect = (select) => {
    const path = [...select.namespace, select.name].join('.');
    setJsonPath(path);
  };

  const convertJsonToXml = () => {
    try {
      const json = JSON.parse(formattedJson);
      const xmlResult = convert.js2xml(json, { compact: true, spaces: 4 });
      setXml(xmlResult);
    } catch (error) {
      toast.error('Error converting JSON to XML.');
    }
  };

  const copyToClipboard = () => {
    const xmlContent = xml;
    navigator.clipboard
      .writeText(xmlContent)
      .then(() => console.log('Content copied to clipboard'))
      .catch((err) => console.error('Failed to copy: ', err));
  };

  const handleCopyJsonPath = () => {
    if (jsonPath) {
      navigator.clipboard
        .writeText(jsonPath)
        .then(() => toast.success('JSON Path copied to clipboard!'))
        .catch(() => toast.error('Failed to copy!'));
    }
  };

  const toggleConversion = () => {
    setIsJsonToXml(!isJsonToXml);
  };

  return (
    <div className=" json-utility-container json-beautifier">
      <Textarea
        value={inputJson}
        onChange={handleJsonChange}
        placeholder="Enter JSON or JavaScript object here"
        className="Input-Textarea"
      />
      <div className="buttons column">
        <Button onClick={toggleConversion}>
          {isJsonToXml ? 'Switch to JSON Parse' : 'Switch to XML Conversion'}
        </Button>
        {isJsonToXml ? (
          <>
            <Button onClick={convertJsonToXml}>Convert to XML</Button>
            <Button onClick={copyToClipboard}>Copy XML</Button>
          </>
        ) : (
          <>
            <Button onClick={handleCopyFormattedJson}>
              Copy Formatted JSON
            </Button>
            <Button onClick={handleCopyJsonPath}>Copy JSON Path</Button>
          </>
        )}
      </div>
      {isJsonToXml && (
        <Textarea
          value={isJsonToXml ? xml : formattedJson}
          placeholder={
            isJsonToXml
              ? 'XML will appear here'
              : 'Formatted JSON will appear here'
          }
          className="output-Textarea"
          readOnly
        />
      )}
      {!isJsonToXml && (
        <div className="column">
          <Input
            type="text"
            value={jsonPath}
            placeholder="JSON Path"
            className="json-path-Input"
            readOnly
          />
          <ReactJson
            src={JSON.parse(formattedJson || '{}')}
            onSelect={handleSelect}
            collapsed={false}
            theme="apathy:inverted"
          />
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default JsonBeautifier;
