import React, { useState } from 'react';
import axios from 'axios';
import './ImageUploader.css';
import '../image.css';
const ImageUploader = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [ttl, setTtl] = useState(24); // Default TTL
    const [compress, setCompress] = useState(true); // Default compress option
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const [uploadError, setUploadError] = useState('');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('imageFile', selectedFile);
        formData.append('ttl', ttl);
        formData.append('compress', compress);

        try {
            const response = await axios.post(`${baseUrl}/image/upload-image`, formData);
            let imageUrl = `https://devessentialsbackend.onrender.com/image/get-image/${response.data.fileId}`;
            if (compress) {
                imageUrl += '?decompress=true';
            }
            setUploadedImageUrl(imageUrl);
            setUploadError('');
        } catch (error) {
            setUploadError('Failed to upload image.');
            console.error('Upload Error:', error);
        }
    };

    return (
        <div className="image-utility-container">
             <h2>Image Uploader</h2>
            <input type="file" onChange={handleFileChange} />
            <div>
                <label>TTL (in hours): </label>
                <input 
                    type="number" 
                    value={ttl} 
                    onChange={(e) => setTtl(e.target.value)} 
                />
            </div>
            <div>
                <label>Compress: </label>
                <input 
                    type="checkbox" 
                    checked={compress} 
                    onChange={(e) => setCompress(e.target.checked)} 
                />
            </div>
            <button onClick={handleUpload}>Upload Image</button>
            {uploadedImageUrl && (
                <div>
                    <textarea value={uploadedImageUrl} readOnly />
                    <button onClick={() => navigator.clipboard.writeText(uploadedImageUrl)}>Copy URL</button>
                    <button onClick={() => window.open(uploadedImageUrl, '_blank')}>Open Image in New Tab</button>
                </div>
            )}
            {uploadError && <p className="error-message">{uploadError}</p>}
        </div>
    );
};

export default ImageUploader;
