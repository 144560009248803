import './BulkConvertImageFormat.css';
import React, { useState } from 'react';
import axios from 'axios';
import Select from '../../../components/Select/Select';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import serviceConstants from '../../../constants/serviceConstants';
import '../image.css';
const BulkConvertImageFormat = () => {
  const [imageFiles, setImageFiles] = useState([]);
  const [outputFormat, setOutputFormat] = useState('png');
  const [convertedImages, setConvertedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const formatOptions = serviceConstants.ImageFormatOptions;
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

  const handleImageChange = (e) => {
    setImageFiles([...e.target.files]);
  };

  const handleFormatChange = (e) => {
    setOutputFormat(e.target.value);
  };

  const handleConvertImages = async () => {
    setIsLoading(true);
    const formData = new FormData();
    imageFiles.forEach((file) => {
      formData.append('imageFiles', file);
    });
    formData.append('format', outputFormat);

    try {
      const response = await axios.post(
        `${baseUrl}/image/bulk-convert-format`,
        formData,
        { responseType: 'blob' }
      );
      setConvertedImages([
        ...convertedImages,
        URL.createObjectURL(response.data),
      ]);
    } catch (error) {
      console.error('Error converting images:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="image-utility-container">
      <h2>Bulk Convert Image Format</h2>
      <Input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageChange}
      />
      <Select
        options={formatOptions}
        value={outputFormat}
        onChange={handleFormatChange}
        placeholder={'Image Format'}
      />

      <Button
        onClick={handleConvertImages}
        disabled={imageFiles.length === 0 || isLoading}
      >
        Convert Format
      </Button>
      {convertedImages.length > 0 && (
        <div>
          <h3>Converted Images:</h3>
          {convertedImages.map((image, index) => (
            <img key={index} src={image} alt={`Converted ${index}`} />
          ))}
        </div>
      )}
    </div>
  );
};

export default BulkConvertImageFormat;
