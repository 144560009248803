import React, { useState } from 'react';
import './JsonToCode.css';

const JsonToCode = () => {
  const [jsonInput, setJsonInput] = useState('');
  const [language, setLanguage] = useState('javascript');
  const [codeOutput, setCodeOutput] = useState('');

  const convertJsonToCode = () => {
    try {
      const jsonObj = JSON.parse(jsonInput);
      let code = '';

      switch (language) {
        case 'javascript':
          code = `const data = ${JSON.stringify(jsonObj, null, 2)};`;
          break;
        case 'python':
          code = `data = ${JSON.stringify(jsonObj, null, 2).replace(/"([^"]+)":/g, '$1:')}`;
          break;
        case 'java':
          // This is a basic conversion. A more robust solution would generate proper Java classes.
          code = `Map<String, Object> data = new HashMap<>() {{
  ${Object.entries(jsonObj).map(([key, value]) => `put("${key}", ${JSON.stringify(value)});`).join('\n  ')}
}};`;
          break;
        default:
          code = 'Unsupported language';
      }

      setCodeOutput(code);
    } catch (error) {
      setCodeOutput('Invalid JSON input');
    }
  };

  return (
    <div className="json-to-code-converter">
      <textarea
        value={jsonInput}
        onChange={(e) => setJsonInput(e.target.value)}
        placeholder="Paste your JSON here..."
      />
      <select value={language} onChange={(e) => setLanguage(e.target.value)}>
        <option value="javascript">JavaScript</option>
        <option value="python">Python</option>
        <option value="java">Java</option>
      </select>
      <button onClick={convertJsonToCode}>Convert to Code</button>
      <textarea
        value={codeOutput}
        readOnly
        placeholder="Code output will appear here..."
      />
    </div>
  );
};

export default JsonToCode;