import React, { useState } from 'react';
import { Parser } from 'json2csv';
import '../json-utility.css'
/**
 * Description
 * @return {any}
 */
function JSONToCsvConverter() {
  const [csvData, setCsvData] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const json = JSON.parse(e.target.result);
        convertJsonToCsv(json);
      };
      reader.readAsText(file);
    }
  };

  const handleJsonInput = (event) => {
    try {
      const json = JSON.parse(event.target.value);
      convertJsonToCsv(json);
    } catch (error) {
      console.error('Invalid JSON input');
    }
  };

  const convertJsonToCsv = (json) => {
    try {
      const parser = new Parser();
      const csv = parser.parse(json);
      setCsvData(csv);
    } catch (error) {
      console.error('Error converting JSON to CSV');
    }
  };

  const downloadCsvFile = () => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'converted.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="json-utility-container">
      <input type="file" onChange={handleFileChange} accept=".json" />
      <textarea onBlur={handleJsonInput} placeholder="Paste JSON here"></textarea>
      {csvData && (
        <>
          <button onClick={downloadCsvFile}>Download CSV</button>
          <pre>{csvData}</pre>
        </>
      )}
    </div>
  );
}

export default JSONToCsvConverter;
