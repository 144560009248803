import React, { useState } from 'react';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import './LockPDF.css';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import '../pdf.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const LockPDF = () => {
  const [pdfFile, setPdfFile] = useState(null);
  const [lockedPdfBlob, setLockedPdfBlob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');

  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLockPDF = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('pdfFile', pdfFile);
    formData.append('password', password);

    try {
      const response = await axios.post(`${baseUrl}/pdf/lock`, formData, {
        responseType: 'blob',
      });
      setLockedPdfBlob(new Blob([response.data], { type: 'application/pdf' }));
    } catch (error) {
      console.error('Error locking PDF:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadLockedPdf = () => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(lockedPdfBlob);
    link.setAttribute('download', 'locked_pdf.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="pdf-utility-container">
      <h2>Lock PDF</h2>
      <Input type="file" accept="application/pdf" onChange={handleFileChange} />
      <Input
        type="password"
        value={password}
        onChange={handlePasswordChange}
        placeholder="Enter Password to Lock PDF"
      />
      <Button onClick={handleLockPDF} disabled={!pdfFile || isLoading}>
        Lock PDF
      </Button>
      {lockedPdfBlob && (
        <div>
          <Document file={lockedPdfBlob}>
            <Page pageNumber={1} width={600} />
          </Document>
          <Button onClick={downloadLockedPdf}>Download Locked PDF</Button>
        </div>
      )}
    </div>
  );
};

export default LockPDF;
