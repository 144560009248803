import React, { useState } from 'react';
import axios from 'axios';
import Select from '../../../components/Select/Select';
import Image from '../../../components/Image/Image';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import serviceConstants from '../../../constants/serviceConstants';
import './ResizeAndConvertImage.css'
import '../image.css';
const ResizeAndConvertImage = () => {
  const [imageFile, setImageFile] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [targetWidth, setTargetWidth] = useState('');
  const [targetHeight, setTargetHeight] = useState('');
  const [format, setFormat] = useState('png');
  const formatOptions = serviceConstants.ImageFormatOptions;
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:2000'
      : 'https://devessentialsbackend.onrender.com';

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleWidthChange = (e) => {
    setTargetWidth(e.target.value);
  };

  const handleHeightChange = (e) => {
    setTargetHeight(e.target.value);
  };

  const handleFormatChange = (e) => {
    setFormat(e.target.value);
  };

  const handleResizeAndConvert = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('imageFile', imageFile);
    formData.append('targetWidth', targetWidth);
    formData.append('targetHeight', targetHeight);
    formData.append('format', format);

    try {
      const response = await axios.post(
        `${baseUrl}/image/resize-convert`,
        formData,
        { responseType: 'blob' }
      );
      setProcessedImage(URL.createObjectURL(response.data));
    } catch (error) {
      console.error('Error processing image:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadProcessedImage = () => {
    const link = document.createElement('a');
    link.href = processedImage;
    link.setAttribute('download', `processed_image.${format}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="image-utility-container">
      <h2>Resize and Convert Image</h2>
      <Input type="file" accept="image/*" onChange={handleImageChange} />
      <Input
        type="number"
        value={targetWidth}
        onChange={handleWidthChange}
        placeholder="Target Width"
      />
      <Input
        type="number"
        value={targetHeight}
        onChange={handleHeightChange}
        placeholder="Target Height"
      />
      <Select
        options={formatOptions}
        value={format}
        onChange={handleFormatChange}
        placeholder={'Image Format'}
      />

      <Button
        onClick={handleResizeAndConvert}
        disabled={!imageFile || isLoading}
      >
        Process Image
      </Button>
      {processedImage && (
        <div>
          <Image src={processedImage} alt="Processed" />
          <Button onClick={downloadProcessedImage}>
            Download Processed Image
          </Button>
        </div>
      )}
    </div>
  );
};

export default ResizeAndConvertImage;
