import React from 'react';
import { motion } from 'framer-motion';
import './Button.css'; // Assuming you have a separate CSS file for styling
import PropTypes from 'prop-types';
const Button = ({ children, onClick, ...props }) => {
  const buttonVariants = {
    hover: {
      scale: 1.05,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transition: { duration: 0.2 },
    },
    tap: {
      scale: 0.95,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      transition: { duration: 0.1 },
    },
  };

  return (
    <motion.button
      onClick={onClick}
      variants={buttonVariants}
      whileHover="hover"
      whileTap="tap"
      {...props}
    >
      {children}
    </motion.button>
  );
};
Button.propTypes = {
  children: PropTypes.node, // Allow any node as children (text, JSX, etc.)
  onClick: PropTypes.func, // Require a function for 'onClick'
};
export default Button;
